import React from 'react';
import Header from '../components/header';
import TextReg from '../components/functionals/textReg';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import AlertCustom from '../components/alertCustom';
import { useNavigate } from 'react-router-dom';

export default function ActivationPage() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');
  // const [password, setPassword] = useState('');
  // const [rePassword, setRePassword] = useState('');
  // const [avatar, setAvatar] = useState('');

  // const handleActivateAccount = () => {
  //   axios
  //     .post(`http://localhost:3011/superadmin-app/register?token=${token}`, {
  //       pass2: password,
  //       pass1: rePassword,
  //       file: avatar,
  //       action: 'manager_confirm',
  //     })
  //     .then((response) => {
  //       // Обработка успешного ответа
  //       console.log('POST запрос отправлен успешно:', response.data);
  //     })
  //     .catch((error) => {
  //       // Обработка ошибки
  //       console.error('Ошибка при отправке POST запроса:', error);
  //     });
  // };
  const [formData, setFormData] = useState({
    pass2: '',
    pass1: '',
    file: [],
    action: 'manager_confirm',
  });

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files); // Преобразовать FileList в массив
    setFormData({
      ...formData,
      file: files,
    });
  };

  //Alert code --->
  const [isActive, setActive] = useState(false);
  const navigate = useNavigate();

  const callAlert = (e) => {
    setActive(true);
    setTimeout(() => {
      setActive(false);
    }, 3000);
  };

  const activeClass = isActive ? 'activeAlert' : '';
  //Alert code end --->

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();
    formDataToSend.append('pass2', formData.pass2);
    formDataToSend.append('pass1', formData.pass1);
    formData.file.forEach((file, index) => {
      formDataToSend.append('file', file);
    });
    formDataToSend.append('action', formData.action);

    try {
      const response = await fetch(`https://bydloss.rocks/superadmin-app/register?token=${token}`, {
        method: 'POST',
        body: formDataToSend,
      });

      if (response.ok) {
        console.log('Данные успешно отправлены в базу данных');
        callAlert();
        setTimeout(() => {
          navigate('/logIn');
        }, 5000);
      } else {
        console.error('Ошибка при отправке данных');
      }
    } catch (error) {
      console.error('Произошла ошибка:', error);
    }
  };

  return (
    <div>
      <AlertCustom className={activeClass} text="Your account was successfully activated!" />
      <Header />
      <div className="logInHolder">
        <div className="loginMain col card aiCenter">
          <div className="sectionHeader lineDivided">
            <TextReg className="text700 textDark textXXL" text="Activate Account" />
          </div>
          <form onSubmit={handleSubmit}>
            <div className="textHolder w100 df">
              <input
                type="password"
                className="customInput btnOutline w100 "
                placeholder="Password"
                name="pass1"
                value={formData.pass1}
                onChange={handleInputChange}
              />
            </div>
            <div className="textHolder w100 df">
              <input
                type="password"
                className="customInput btnOutline w100 df"
                placeholder="Repeat Password"
                name="pass2"
                value={formData.pass2}
                onChange={handleInputChange}
                multiple
              />
            </div>
            <div className="textHolder w100 df">
              <input
                className="customInput btnOutline w100"
                type="file"
                name="file"
                onChange={handleImageChange}
                multiple
              />
            </div>

            <button className="btn btnSolid text700" type="submit">
              Activate
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
