import React from 'react';
import ProductCard from './productCard';
import SectionHeader from './functionals/sectionHeader';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Shevron from '../img/shevron.svg';


export default function SaleOffers() {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://bydloss.rocks/api-v2/data?page=${currentPage}`);
        setProducts(response.data.data); // Обратите внимание на response.data.data
        setTotalPages(response.data.total_pages);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, [currentPage]);

  //Truncate code start --->
  const truncateString = (str, maxLength) => {
    if (str.length > maxLength) {
        return str.slice(0, maxLength) + '...';
    }
    return str;
  };
  //Truncate code start --->

  //Pagination code start --->
  const nextPage = () => {
    if (currentPage + 1 > totalPages) {
      void 0;
    } else {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage - 1 < 1) {
      void 0;
    } else {
      setCurrentPage(currentPage - 1);
    }
  };

  const goToPage = (page) => {
    setCurrentPage(page);
  };
  //Pagination code end --->

  return (
    <section className="section">
      <div className="container">
        {loading ? (
          <p>Loading...</p>
        ) : (
          <>
          <div className="df flw aiTop productsHolder">
            {Array.isArray(products) &&
              products.map((product) => (
                <div className="productOffer mb10" key={product.vendorCode}>
                  <Link to={`/products/${product.vendorCode}`}>
                    <ProductCard
                      img={product.images[0].imageURL} // Используем первое изображение в качестве примера
                      brandName={truncateString(product.name, 20)}
                      vendorCode={product.vendorCode}
                      description={product.properties.properties1}
                      price={product.price}
                      sizes={product.properties.properties3}
                    />
                  </Link>
                </div>
              ))}
          </div>
          {totalPages > 0 && (
            <div className="pagination">
              <div className="paginationMain">
                <button
                  className=" btn btnOutline pagePrev"
                  onClick={prevPage}
                  disabled={currentPage === 1}>
                  <img src={Shevron} />
                </button>
                <div className="paginationList">
                  {/* Добавляем кнопки для каждой страницы */}
                  {Array.from({ length: totalPages }, (_, index) => (
                    <button
                      className="pageBtn btn btnOutline"
                      key={index}
                      onClick={() => goToPage(index + 1)}>
                      {index + 1}
                    </button>
                  ))}
                </div>
                <button
                  className=" btn btnOutline pageNext"
                  onClick={nextPage}
                  disabled={currentPage === totalPages}>
                  <img src={Shevron} />
                </button>
              </div>
              {/* Отображаем общее количество страниц */}

              <div className="sectionHeader lineDivided">
                <span className="text700 textDark textXs">
                  {currentPage}/{totalPages}
                </span>
              </div>
            </div>
          )}
          </>
        )}
      </div>
    </section>
  );
}
