import React from 'react';
import Button from './functionals/button';
import { useNavigate } from 'react-router-dom';
import Logo from './functionals/logo';
import { Link } from 'react-router-dom';
import Cart from './cart';
import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import atob from 'atob';
import { useUser } from '../contexts/useUser';
import CartIco from '../img/Vector.svg';
import BalanceIco from '../img/balance.svg';
import Compare from './compare';

export default function Header() {
  const { setUser } = useUser();
  // Check if token exists

  const [isUserAuthenticated, setUserAuthenticated] = useState(false);

  useEffect(() => {
    // Функция для проверки наличия токена в куках
    const checkTokenInCookies = () => {
      const cookies = document.cookie;
      console.log('Cookies at authentication check:', cookies);
      const cookieArray = cookies.split(';');
      for (const cookie of cookieArray) {
        const trimmedCookie = cookie.trim();
        if (trimmedCookie.startsWith('token=')) {
          return true;
        }
      }
      return false;
    };

    // Проверяем наличие токена при загрузке компонента
    const isAuthenticated = checkTokenInCookies();
    setUserAuthenticated(isAuthenticated);
  }, []); // Пустой массив зависимостей гарантирует выполнение эффекта только при монтировании

  const [token, setToken] = useState('');
  const [email, setEmail] = useState('');
  const [userType, setUserType] = useState({});

  useEffect(() => {
    const tokenFromCookie = Cookies.get('token');
    console.log('Token from cookie:', tokenFromCookie);

    if (tokenFromCookie) {
      setToken(tokenFromCookie);
      try {
      const decodedToken = atob(tokenFromCookie.split('.')[1]);
      console.log('Decoded token:', decodedToken);

      const tokenData = JSON.parse(decodedToken);
      console.log('Token data:', tokenData);

      const userEmail = tokenData.userId;
      console.log('User email:', userEmail);

      const userTypeInfo = tokenData.userType;
      setUserType(userTypeInfo);
      setEmail(userEmail);
      setUser({
        email: userEmail,
        userType: userTypeInfo,
      });
      } catch (error) {
        console.error('Ошибка при декодировании токена:', error); // Логирование ошибки
      }
    }
  }, [setUser]);

  //Logout
  const handleLogout = () => {
    // Ваша логика для выхода пользователя
    // После выхода удалите токен из куков
    document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 GMT';
    setUserAuthenticated(false);
  };
  // Check if token exists

  const [isActive, setActive] = useState(false);
  const [sbActive, setSbActive] = useState(false);
  const [cartActive, setcartActive] = useState(false);
  const [cabActive, setcabActive] = useState(false);
  const [compareActive, setCompareActive] = useState(false);


  const handleToggleClick = (e) => {
    setActive(!isActive);
  };
  const handleSidebar = (e) => {
    setSbActive(!sbActive);
  };
  const handleCart = (e) => {
    setcartActive(!cartActive);
  };
  const closeCart = (e) => {
    setcartActive(false);
  };
  const handleToggleCab = (e) => {
    setcabActive(!cabActive);
  };

  const handleCompare = (e) => {
    setCompareActive(!compareActive);
  };
  const closeCompare = (e) => {
    setCompareActive(false);
  };

  const activeClass = isActive ? 'catListActive' : '';
  const activeCab = cabActive ? 'catListActive' : '';

  const sbActiveL = sbActive ? 'sbActive' : '';
  const cartActiveL = cartActive ? 'modalActive' : '';
  const compareActiveL = compareActive ? 'modalActive' : '';


  // Search code --->

  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  const handleSearch = () => {
    navigate(`/category/${searchTerm}`);
  };

  // Search code end --->

  const [cartItems, setCartItems] = useState([]);

  useEffect(() => {
    // Функция для получения корзины из кук
    const getCartFromCookies = () => {
      const existingCart = Cookies.get('cart');
      const existingCartArray = existingCart ? JSON.parse(existingCart) : [];
      setCartItems(existingCartArray);
    };

    // Вызываем функцию при загрузке компонента
    getCartFromCookies();

    // Отслеживаем изменения в куках
    const intervalId = setInterval(() => {
      getCartFromCookies();
    }, 1000); // Периодичность проверки, например, каждую секунду

    // Очистка интервала при размонтировании компонента
    return () => clearInterval(intervalId);
  }, []);

  const [compareItems, setCompareItems] = useState([]);

  useEffect(() => {
    // Функция для получения корзины из кук
    const getCartFromCookies = () => {
      const existingCart = Cookies.get('compare');
      const existingCartArray = existingCart ? JSON.parse(existingCart) : [];
      setCompareItems(existingCartArray);
    };

    // Вызываем функцию при загрузке компонента
    getCartFromCookies();

    // Отслеживаем изменения в куках
    const intervalId = setInterval(() => {
      getCartFromCookies();
    }, 1000); // Периодичность проверки, например, каждую секунду

    // Очистка интервала при размонтировании компонента
    return () => clearInterval(intervalId);
  }, []);

  return (
    <header className="header">
      <div className="container">
        <div className="headerContent">
          <div className="df aiCenter">
            <div
              onClick={() => {
                handleSidebar();
              }}>
              <Button className="btn mobileNavToggler" text="&equiv;" />
            </div>
            <Link to="/">
              <Logo />
            </Link>
            <div
              onClick={() => {
                handleToggleClick();
              }}>
              <Button text="All categories" className="btn btnSolid btnWider textLight" />
            </div>
            <div className={`catList ${activeClass}`}>
              <Link className="btn" to="/category/Vine">
                Vine
              </Link>
              <Link className="btn" to="/category/Beer">
                Beer
              </Link>
              <Button text="Children" className="btn" />
              <Button text="House" className="btn" />
              <Button text="Health&Beauty" className="btn" />
              <Button text="Garden" className="btn" />
              <Button text="Tourism" className="btn" />
              <Button text="Sport" className="btn" />
              <Button text="Kitchen" className="btn" />
              <Button text="Technique" className="btn" />
              <Button text="Others" className="btn" />
            </div>
            <div className="hSearch">
              <input
                type="text"
                placeholder="Введите категорию..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="customInput"
              />
              <button className="btn" onClick={handleSearch}>
                Искать
              </button>
            </div>
          </div>
          <div className="df">
            {/* <div className="lang btnOutline">
              <Button text="UA" className="btn childDivided" />
              <Button text="EN" className="btn childDivided" />
            </div> */}
            <div
              onClick={() => {
                handleToggleCab();
              }}>
              <Button className="btn location" text={email} />
            </div>
            <div className={`cabList ${activeCab}`}>
              {userType.status === 'superuser' && (
                <>
                  <Link className="btn" to="/rootAdmin">
                    Root Admin
                  </Link>
                </>
              )}
              {userType.status === 'manager' && (
                <>
                  <Link className="btn" to="/managerCabinet">
                    Manager Cabinet
                  </Link>
                  <Link to="/createProduct">
                    <Button text="Create Product" className="btn" />
                  </Link>
                </>
              )}
              {userType.status === 'user' && (
                <>
                  <Link className="btn" to="/userCabinet">
                    userCabinet
                  </Link>
                </>
              )}
            </div>
            <div
              className="dfCenter posR"
              onClick={() => {
                handleCart();
              }}>
              {cartItems.length > 0 ? (
                <div className="cartBubble">&nbsp;</div>
              ) : (
                <span className="">&nbsp;</span>
              )}
              <button className="btn">
                <img src={CartIco} />
              </button>
            </div>
            <div
              className="dfCenter posR"
              onClick={() => {
                handleCompare();
              }}>
              {compareItems.length > 0 ? (
                <div className="cartBubble">&nbsp;</div>
              ) : (
                <span className="">&nbsp;</span>
              )}
              <button className="btn">
                <img src={BalanceIco} />
              </button>
            </div>
            {isUserAuthenticated ? (
              <>
                <Button text="Exit" className="btn" onClick={handleLogout} />
              </>
            ) : (
              <div className="login btnUnderline">
                <Link to="/logIn">
                  <Button text="Log In" className="btn childDivided" />
                </Link>
                <Link to="/signUp">
                  <Button text="Sign Up" className="btn childDivided" />
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={`sidebarMobile ${sbActiveL}`}>
        <div className="hSearch">
          <input
            type="text"
            placeholder="Введите категорию..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="customInput"
          />
          <button className="btn" onClick={handleSearch}>
            Искать
          </button>
        </div>
        {userType.status === 'superuser' && (
          <>
            <Link className="btn" to="/rootAdmin">
              Root Admin
            </Link>
          </>
        )}
        {userType && userType.status === 'manager' && (
          <>
            <Link className="btn" to="/rootAdmin">
              Root Admin
            </Link>
            <Link className="btn" to="/managerCabinet">
              Manager Cabinet
            </Link>
            <Link to="/createProduct">
              <Button text="Create Product" className="btn" />
            </Link>
          </>
        )}
        {userType.status === 'user' && (
          <>
            <Link className="btn" to="/userCabinet">
              userCabinet
            </Link>
          </>
        )}
        {/* <div className="lang btnOutline">
          <Button text="UA" className="btn childDivided" />
          <Button text="EN" className="btn childDivided" />
        </div> */}
        {/* <button className="btn location">Location</button> */}
        {isUserAuthenticated ? (
          <>
            <Button text="Exit" className="btn" onClick={handleLogout} />
          </>
        ) : (
          <div className="login btnUnderline">
            <Link to="/logIn">
              <Button text="Log In" className="btn childDivided" />
            </Link>
            <Link to="/signUp">
              <Button text="Sign Up" className="btn childDivided" />
            </Link>
          </div>
        )}
        <div className="mobSidebar">
          <div className="headerCategories col">
            <Link className={`btn ${activeClass}`} to="/category/Vine">
              Vine
            </Link>
            <Link className={`btn ${activeClass}`} to="/category/Sort Test">
              Sort Test
            </Link>
            <Link className={`btn ${activeClass}`} to="/category/lolypop">
              Lolypop
            </Link>
            <Link className={`btn ${activeClass}`} to="/category/Pagination test">
              Pagination test
            </Link>
            <Link className={`btn ${activeClass}`} to="/category/Vine">
              Vine
            </Link>
            <Link className={`btn ${activeClass}`} to="/category/Beer">
              Beer
            </Link>
            <Link className="btn" to="/category/lolypop">
              Lolypop
            </Link>
            <Link className={`btn ${activeClass}`} to="/category/Pagination test">
              Pagination test
            </Link>
            <Link className={`btn ${activeClass}`} to="/category/Vine">
              Vine
            </Link>
            <Link className={`btn ${activeClass}`} to="/category/Beer">
              Beer
            </Link>
            <Link className={`btn ${activeClass}`} to="/category/lolypop">
              Lolypop
            </Link>
            <Link className={`btn ${activeClass}`} to="/category/Pagination test">
              Pagination test
            </Link>
          </div>
        </div>
      </div>
      <div className={`modal ${cartActiveL}`}>
        <div className="modalBody">
          <div className="modalHeader dfBtw aiCenter">
            <div className="itemBlank">
              <span className="text700 textDark textXXL">Cart</span>
            </div>
            <div
              className="itemBlank"
              onClick={() => {
                closeCart();
              }}>
              <Button className="btn text300 textDark textXl" text="&times;" />
            </div>
          </div>
          <Cart />
        </div>
      </div>
      <div className={`modal ${compareActiveL}`}>
        <div className="modalBody">
          <div className="modalHeader dfBtw aiCenter">
            <div className="itemBlank">
              <span className="text700 textDark textXXL">Comparison</span>
            </div>
            <div
              className="itemBlank"
              onClick={() => {
                closeCompare();
              }}>
              <Button className="btn text300 textDark textXl" text="&times;" />
            </div>
          </div>
          <Compare />
        </div>
      </div>
    </header>
  );
}
