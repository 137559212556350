import React from 'react';
import Button from '../components/functionals/button';
import CustomInput from '../components/functionals/customInput';
import TextReg from '../components/functionals/textReg';
import Alert from '../components/functionals/alert';
import Header from '../components/header';
import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../components/sidebar';

export default function OrderPage(props) {
  // Check if token exists --->

  const [isUserAuthenticated, setUserAuthenticated] = useState(false);

  useEffect(() => {
    // Функция для проверки наличия токена в куках
    const checkTokenInCookies = () => {
      const cookies = document.cookie;
      const cookieArray = cookies.split(';');
      for (const cookie of cookieArray) {
        const trimmedCookie = cookie.trim();
        if (trimmedCookie.startsWith('token=')) {
          return true;
        }
      }
      return false;
    };

    // Проверяем наличие токена при загрузке компонента
    const isAuthenticated = checkTokenInCookies();
    setUserAuthenticated(isAuthenticated);
  }, []); // Пустой массив зависимостей гарантирует выполнение эффекта только при монтировании

  // Check if token exists end --->

  const [cartItems, setCartItems] = useState([]);

  //Form Submit Code
  const [card_number, setCard_number] = useState('');
  const [expiry_date, setExpiry_date] = useState('');
  const [cvv, setCvv] = useState('');
  const [street, setStreet] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [postal_code, setPostal_code] = useState('');
  const [country, setCountry] = useState('');
  const [streetShip, setStreetShip] = useState('');
  const [cityShip, setCityShip] = useState('');
  const [stateShip, setStateShip] = useState('');
  const [postal_codeShip, setPostal_codeShip] = useState('');
  const [countryShip, setCountryShip] = useState('');
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [token, setToken] = useState('');

  useEffect(() => {
    const tokenFromCookie = Cookies.get('token');
    console.log('Token from cookie:', tokenFromCookie);

    if (tokenFromCookie) {
      setToken(tokenFromCookie);

      const decodedToken = atob(tokenFromCookie.split('.')[1]);
      console.log('Decoded token:', decodedToken);

      const tokenData = JSON.parse(decodedToken);
      console.log('Token data:', tokenData);

      const userEmail = tokenData.userId;
      console.log('User email:', userEmail);

      setEmail(userEmail);
    }
  }, []);

  useEffect(() => {
    // Функция для получения корзины из кук
    const getCartFromCookies = () => {
      const existingCart = Cookies.get('cart');
      const existingCartArray = existingCart ? JSON.parse(existingCart) : [];
      setCartItems(existingCartArray);
    };

    // Вызываем функцию при загрузке компонента
    getCartFromCookies();

    // Отслеживаем изменения в куках
    const intervalId = setInterval(() => {
      getCartFromCookies();
    }, 1000); // Периодичность проверки, например, каждую секунду

    // Очистка интервала при размонтировании компонента
    return () => clearInterval(intervalId);
  }, []);
  // Рассчитайте полную стоимость товаров
  const totalCost = cartItems.reduce((total, item) => total + item.price * item.quantity, 0);

  //Form Submit Code Start --->

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    // Другие действия, если необходимо
  };

  const handleOrder = async () => {
    if (
      !card_number ||
      !expiry_date ||
      !cvv ||
      !street ||
      !city ||
      !state ||
      !postal_code ||
      !country ||
      !streetShip ||
      !cityShip ||
      !stateShip ||
      !postal_codeShip ||
      !countryShip
    ) {
      alert('Please fill in all fields');
      return;
    }
    const items = cartItems.map((item) => ({
      vendor_code: item.vendorC,
      quantity: item.quantity,
    }));
    const requestData = {
      login: email,
      status: 'new',
      items: items,
      payment_details: {
        card_number: card_number,
        expiry_date: expiry_date,
        cvv: cvv,
        billing_address: {
          street: street,
          city: city,
          state: state,
          postal_code: postal_code,
          country: country,
        },
      },
      shipping_address: {
        streetShip: streetShip,
        cityShip: cityShip,
        stateShip: stateShip,
        postal_codeShip: postal_codeShip,
        countryShip: countryShip,
      },
    };
    // Отправьте запрос на сервер для аутентификации пользователя
    const response = await axios.post(`https://bydloss.rocks/request-node-order-backend/orderBackend/`, requestData);

    // Обработайте ответ от сервера
    if (response.status === 200) {
      Cookies.remove('cart');
      // setTimeout(() => {
      //   navigate('/');
      // }, 3000);
    } else {
      // Обработайте ошибку аутентификации
      alert('Error occured try again later =(');
    }
  };

  //Form Submit Code End --->

  const [isActive, setActive] = useState(false);

  const handleToggleClick = (e) => {
    setActive(true);
    setTimeout(() => {
      setActive(false);
    }, 3000);
  };

  const activeClass = isActive ? 'modalActive' : '';

  return (
    <>
      <Header />
      <div className="order">
        <Sidebar />
        <div className="container">
          <div className="orderMain dfBtw flw">
            <div className="orderBody w70">
              <div className="orderHeading lineDivided">
                <TextReg className="text700 textDark textMd" text="Order registration" />
              </div>

              <div className="lineDivided">
                <TextReg className="text500 textDark textMd" text="Seller: Adolf" />
              </div>
              {cartItems.map((item, index) => (
                <div className="cartProduct dfBtw aiTop lineDivided flw" key={index}>
                  <div className="df aiTop">
                    <div className="cartProductImg">
                      <img src={item.imageUrl} alt="product" />
                    </div>
                    <div className="cartProductName">
                      <TextReg className="text300 textDark textSm" text={item.name} />
                    </div>
                  </div>
                  <div className="df aiTop">
                    <div className="cartCalculation col tar">
                      <TextReg className="text300 textMuted textXs" text="Price" />
                      <TextReg
                        className="text500 textDark textSm"
                        text={item.price * item.quantity}
                      />
                    </div>
                    <div className="cartCalculation col tac">
                      <TextReg className="text300 textMuted textXs" text="Amount" />
                      <div className="df centered">
                        <TextReg className="text500 textDark textSm" text={item.quantity} />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              <div className="cartCalculation col tar">
                <TextReg className="text300 textMuted textXs" text="Total" />
                <TextReg className="text500 textDark textSm" text={totalCost} />
              </div>
              <div className="dividerH">&nbsp;</div>
              <div className="orderHeading lineDivided">
                <TextReg className="text700 textDark textMd" text="Delivery way" />
              </div>
              <div className="lineDivided w100">
                <Button className="delWay btnOutline w100 tal" text="Nova Poshta" />
              </div>
              <div className="dividerH">&nbsp;</div>
              {/* <div className="orderHeading lineDivided">
                <TextReg className="text700 textDark textMd" text="Payment method" />
              </div> */}
              {/* <div className="payWayItem lineDivided w100">
                <div className="delWay tal df aiCenter">
                  <input type="radio" />
                  <div className="df aiCenter">
                    <div className="payMethodIcon">
                      <img src="img/card.png" alt="" />
                    </div>
                    <div className="col">
                      <span className="textDark text700 textReg">Bank Card</span>
                      <span className="textMuted text400 textReg">Pre payment</span>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* <div className="payWayItem lineDivided w100">
                <div className="delWay tal df aiCenter">
                  <input type="radio" />
                  <div className="df aiCenter">
                    <div className="payMethodIcon">
                      <img src="img/card.png" alt="" />
                    </div>
                    <div className="col">
                      <span className="textDark text700 textReg">Cash</span>
                      <span className="textMuted text400 textReg">
                        After delivery <br />
                        adiitional conission: <br />+ 2% Delivery commisions
                      </span>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="dividerH">&nbsp;</div>

              <form onSubmit={handleFormSubmit}>
                {isUserAuthenticated ? (
                  <div>
                    <div className="orderHeading lineDivided">
                      <TextReg className="text700 textDark textMd" text="Card data" />
                    </div>
                    <div className="dfBtw flw">
                      <div className="dataForm col tal w50">
                        <div className="lineDivided">
                          <TextReg className="text700 textDark textSm" text="Card Number" />
                        </div>
                        <CustomInput
                          type="text"
                          className="customInput"
                          placeholder=""
                          onChange={(e) => setCard_number(e.target.value)}
                        />
                      </div>
                      <div className="dataForm col tal w50">
                        <div className="lineDivided">
                          <TextReg className="text700 textDark textSm" text="Exp Date" />
                        </div>
                        <CustomInput
                          type="text"
                          className="customInput"
                          placeholder=""
                          onChange={(e) => setExpiry_date(e.target.value)}
                        />
                      </div>
                      <div className="dataForm col tal w50">
                        <div className="lineDivided">
                          <TextReg className="text700 textDark textSm" text="CVV" />
                        </div>
                        <CustomInput
                          type="text"
                          className="customInput"
                          placeholder=""
                          onChange={(e) => setCvv(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="dividerH">&nbsp;</div>
                    <div className="orderHeading lineDivided">
                      <TextReg className="text700 textDark textMd" text="Billing data" />
                    </div>
                    <div className="dfBtw flw">
                      <div className="dataForm col tal w50">
                        <div className="lineDivided">
                          <TextReg className="text700 textDark textSm" text="Street" />
                        </div>
                        <CustomInput
                          type="text"
                          className="customInput"
                          placeholder=""
                          onChange={(e) => setStreet(e.target.value)}
                        />
                      </div>
                      <div className="dataForm col tal w50">
                        <div className="lineDivided">
                          <TextReg className="text700 textDark textSm" text="City" />
                        </div>
                        <CustomInput
                          type="text"
                          className="customInput"
                          placeholder=""
                          onChange={(e) => setCity(e.target.value)}
                        />
                      </div>
                      <div className="dataForm col tal w50">
                        <div className="lineDivided">
                          <TextReg className="text700 textDark textSm" text="State" />
                        </div>
                        <CustomInput
                          type="text"
                          className="customInput"
                          placeholder=""
                          onChange={(e) => setState(e.target.value)}
                        />
                      </div>
                      <div className="dataForm col tal w50">
                        <div className="lineDivided">
                          <TextReg className="text700 textDark textSm" text="Postal Code" />
                        </div>
                        <CustomInput
                          type="number"
                          className="customInput"
                          placeholder=""
                          onChange={(e) => setPostal_code(e.target.value)}
                        />
                      </div>
                      <div className="dataForm col tal w50">
                        <div className="lineDivided">
                          <TextReg className="text700 textDark textSm" text="Country" />
                        </div>
                        <CustomInput
                          type="text"
                          className="customInput"
                          placeholder="Country"
                          onChange={(e) => setCountry(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="dividerH">&nbsp;</div>
                    <div className="orderHeading lineDivided">
                      <TextReg className="text700 textDark textMd" text="Shipping data" />
                    </div>
                    <div className="dfBtw flw">
                      <div className="dataForm col tal w50">
                        <div className="lineDivided">
                          <TextReg className="text700 textDark textSm" text="Street" />
                        </div>
                        <CustomInput
                          type="text"
                          className="customInput"
                          placeholder=""
                          onChange={(e) => setStreetShip(e.target.value)}
                        />
                      </div>
                      <div className="dataForm col tal w50">
                        <div className="lineDivided">
                          <TextReg className="text700 textDark textSm" text="City" />
                        </div>
                        <CustomInput
                          type="text"
                          className="customInput"
                          placeholder=""
                          onChange={(e) => setCityShip(e.target.value)}
                        />
                      </div>
                      <div className="dataForm col tal w50">
                        <div className="lineDivided">
                          <TextReg className="text700 textDark textSm" text="State" />
                        </div>
                        <CustomInput
                          type="text"
                          className="customInput"
                          placeholder=""
                          onChange={(e) => setStateShip(e.target.value)}
                        />
                      </div>
                      <div className="dataForm col tal w50">
                        <div className="lineDivided">
                          <TextReg className="text700 textDark textSm" text="Postal Code" />
                        </div>
                        <CustomInput
                          type="number"
                          className="customInput"
                          placeholder=""
                          onChange={(e) => setPostal_codeShip(e.target.value)}
                        />
                      </div>
                      <div className="dataForm col tal w50">
                        <div className="lineDivided">
                          <TextReg className="text700 textDark textSm" text="Country" />
                        </div>
                        <CustomInput
                          type="text"
                          className="customInput"
                          placeholder="Post Adress"
                          onChange={(e) => setCountryShip(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="dividerH">&nbsp;</div>
                  </div>
                ) : (
                  <div>
                    <div className="orderHeading lineDivided">
                      <TextReg className="text700 textDark textMd" text="Contact data" />
                    </div>
                    <div className="dfBtw flw">
                      <div className="dataForm col tal w50">
                        <div className="lineDivided">
                          <TextReg className="text700 textDark textSm" text="Name" />
                        </div>
                        <CustomInput type="text" className="customInput" placeholder="Name" />
                      </div>
                      <div className="dataForm col tal w50">
                        <div className="lineDivided">
                          <TextReg className="text700 textDark textSm" text="Surname" />
                        </div>
                        <CustomInput type="text" className="customInput" placeholder="Surname" />
                      </div>
                      <div className="dataForm col tal w50">
                        <div className="lineDivided">
                          <TextReg className="text700 textDark textSm" text="Email" />
                        </div>
                        <CustomInput type="text" className="customInput" placeholder="Email" />
                      </div>
                      <div className="dataForm col tal w50">
                        <div className="lineDivided">
                          <TextReg className="text700 textDark textSm" text="Phone" />
                        </div>
                        <CustomInput type="number" className="customInput" placeholder="Phone" />
                      </div>
                      <div className="dataForm col tal w50">
                        <div className="lineDivided">
                          <TextReg className="text700 textDark textSm" text="Post Adress" />
                        </div>
                        <CustomInput
                          type="text"
                          className="customInput"
                          placeholder="Post Adress"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </form>
            </div>
            <div className="orderTotal col w25">
              <div className="orderHeading lineDivided">
                <TextReg className="text700 textDark textMd" text="Total" />
              </div>
              <div className="lineDivided dfBtw aiCenter">
                <TextReg className="text300 textDark textReg" text="Product cost" />
                <TextReg className="text300 textDark textReg" text={totalCost} />
              </div>
              <div className="lineDivided dfBtw aiCenter">
                <TextReg className="text300 textDark textReg" text="Delivery" />
                <TextReg className="text300 textDark textReg" text="Nova Poshta" />
              </div>
              <div className="lineDivided">
                <div className="dividerH">&nbsp;</div>
              </div>
              <div className="lineDivided">
                <div className="lineDivided dfBtw aiCenter">
                  <TextReg className="text500 textDark textReg" text="Total" />
                  <TextReg className="text700 textDark textReg" text={totalCost} />
                </div>
              </div>
              <div className="lineDivided">
                <div
                  onClick={() => {
                    handleToggleClick();
                  }}>
                  <Button
                    className="btnSolid w100 text700"
                    text="Register order"
                    onClick={handleOrder}
                  />
                </div>
                <div className={`alertWrapper ${activeClass}`}>
                  <Alert text="Order registered successfully!" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
