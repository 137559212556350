import React from 'react';
import Shirt from '../img/shirt.jpg';
import SizeTableSm from './functionals/sizeTableSm';
import { Link } from 'react-router-dom';

export default function ProductCard(props) {
  return (
    <div className="card ">
      <div className="cardImg mb20">
        <img src={props.img} alt="" />
        <div className="cardLight topInfo dfCenter">
          <span className="ttu textXs text700 textDark">sale</span>
        </div>
        
      </div>
      <div className="cardTxt">
        <div className="brandName">
          <span className="text700 textDark textFFSec textSm textLighter">{props.brandName}</span>
        </div>
        <div className="cardTxtItem">
          <span className="ttu text500 textDark textXl"> {props.price} uah</span>
        </div>
        {/* 
        <div className="cardTxtItem prodDescr">
          <span className="text300 textDark textReg">{props.description}</span>
        </div>
        <SizeTableSm sizes={props.sizes} /> */}
      </div>
    </div>
  );
}
