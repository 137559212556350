import React from 'react';
export default function Logo() {
  return (
    <div className="logo">
      <span className="text700 textDark">
        MarketPlace
      </span>
    </div>
  );
}
