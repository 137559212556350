import ProductCard from '../components/productCard';
import Button from '../components/functionals/button';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
// import CheckBox from '../components/functionals/checkBox';
import Header from '../components/header';
import axios from 'axios';
import Shevron from '../img/shevron.svg';
import Sidebar from '../components/sidebar';

export default function CategoryPage() {
  //Filter code start --->
  const { category } = useParams();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [sortBy, setSortBy] = useState(null);
  const [activeButton, setActiveButton] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://bydloss.rocks/api-v2/data?page=${currentPage}&search_property=${category}${
            sortBy ? `&sort_by=${sortBy}` : ''
          }`,
        );
        setProducts(response.data.data);
        setTotalPages(response.data.total_pages);
        setLoading(false);
        console.log(sortBy);
      } catch (error) {
        console.error('Error fetching category data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, [category, currentPage, sortBy]);

  //Filter code end --->

  //Pagination code start --->

  const nextPage = () => {
    if (currentPage + 1 > totalPages) {
      void 0;
    } else {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage - 1 < 1) {
      void 0;
    } else {
      setCurrentPage(currentPage - 1);
    }
  };

  const goToPage = (page) => {
    setCurrentPage(page);
  };

  //Pagination code end --->

  const [sbActive, setSbActive] = useState(false);
  const handleSidebar = (e) => {
    setSbActive(!sbActive);
  };
  const closeSidebar = (e) => {
    setSbActive(false);
  };
  const sbActiveL = sbActive ? 'sbActive' : '';

  //sorting function start --->
  const handleSortBy = (sortByValue) => {
    if (sortBy !== sortByValue) {
      setSortBy(sortByValue);
      setActiveButton(sortByValue);
    }
  };
  //sorting function end --->

  return (
    <div>
      <Header />

      <main className="main">
        <Sidebar />
        <div className="container">
          <div className="categoryMain df">
            {/* <div className={`categoryFilter w25 ${sbActiveL}`}>
              <div className="sectionHeader lineDivided dfBtw aiCenter">
                <span className="text500 textDark textMd">Filters :</span>
                <div
                  onClick={() => {
                    closeSidebar();
                  }}
                  className="ttu text700 textDark textMd tar fCloser">
                  &times;
                </div>
              </div>
              <div className="textHolder">
                <div className="sectionHeader lineDivided">
                  <span className="text700 textDark textMd">Size</span>
                </div>
                <div className="col">
                  <CheckBox text="XS" />
                  <CheckBox text="S" />
                  <CheckBox text="M" />
                  <CheckBox text="L" />
                  <CheckBox text="XL" />
                  <CheckBox text="XXL" />
                </div>
              </div>
              <div className="textHolder">
                <div className="sectionHeader lineDivided">
                  <span className="text700 textDark textMd">State</span>
                </div>
                <div className="col">
                  <CheckBox text="New" />
                  <CheckBox text="Perfect" />
                  <CheckBox text="Very good" />
                  <CheckBox text="Good" />
                  <CheckBox text="Satisfying" />
                </div>
              </div>
              <div className="textHolder">
                <div className="sectionHeader lineDivided">
                  <span className="text700 textDark textMd">Price</span>
                </div>
                <div className="col">
                  <CheckBox text="before 100 UAH" />
                  <CheckBox text="from 100 to 300 UAH" />
                  <CheckBox text="from 300 to 500 UAH" />
                  <CheckBox text="from 500 to 1000 UAH" />
                  <CheckBox text="more than 1000 UAH" />
                </div>
              </div>
            </div> */}
            <div className={`categoryGoodsList w100 `}>
              <div className="sortBy dfBtw aiCenter lineDivided ">
                <div
                  onClick={() => {
                    handleSidebar();
                  }}>
                  <Button text="&#8788;" className="btnOutline fToggler" />
                </div>
                <div className="dfBtw">
                  <span className="text900 textDark textMd">Sort by:</span>
                  <button
                    className={`btn btnOutline ${activeButton === 'date' ? 'active' : ''}`}
                    onClick={() => handleSortBy('date')}>
                    Date
                  </button>
                  <button
                    className={`btn btnOutline ${activeButton === 'price' ? 'active' : ''}`}
                    onClick={() => handleSortBy('price')}>
                    Price
                  </button>
                </div>
              </div>
              {loading ? (
                <p>Loading...</p>
              ) : (
                <div className="df flw aiTop productsHolder">
                  {Array.isArray(products) &&
                    products.map((product) => (
                      <div className="productOffer" key={product.vendorCode}>
                        <Link to={`/products/${product.vendorCode}`}>
                          <ProductCard
                            img={product.images[0].imageURL} // Используем первое изображение в качестве примера
                            brandName={product.name}
                            vendorCode={product.vendorCode}
                            description={product.properties.properties1}
                            price={product.price}
                            sizes={product.properties.properties3}
                          />
                        </Link>
                      </div>
                    ))}
                </div>
              )}
              {totalPages > 0 && (
                <div className="pagination">
                  <div className="paginationMain">
                    <button
                      className=" btn btnOutline pagePrev"
                      onClick={prevPage}
                      disabled={currentPage === 1}>
                      <img src={Shevron} />
                    </button>
                    <div className="paginationList">
                      {/* Добавляем кнопки для каждой страницы */}
                      {Array.from({ length: totalPages }, (_, index) => (
                        <button
                          className="pageBtn btn btnOutline"
                          key={index}
                          onClick={() => goToPage(index + 1)}>
                          {index + 1}
                        </button>
                      ))}
                    </div>
                    <button
                      className=" btn btnOutline pageNext"
                      onClick={nextPage}
                      disabled={currentPage === totalPages}>
                      <img src={Shevron} />
                    </button>
                  </div>
                  {/* Отображаем общее количество страниц */}

                  <div className="sectionHeader lineDivided">
                    <span className="text700 textDark textXs">
                      {currentPage}/{totalPages}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
