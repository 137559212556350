import React from 'react';
export default function CheckBox(props) {
  return (
    <div className="filterItem">
      <form>
        <input type="checkbox" />
        <label>{props.text}</label>
      </form>
    </div>
  );
}
