import React from 'react';
import TextReg from './functionals/textReg';

export default function AlertCustom(props) {
  return (
    <>
      <div className={`alertCustomMain ${props.className}`}>
        <div className="alertCustomBody">
          <TextReg className="text700 textDark textLg" text={props.text} />
        </div>
      </div>
    </>
  );
}
