import SaleOffers from '../components/saleOffers';
import About from '../components/about';
import Footer from '../components/footer';
import React from 'react';
import Header from '../components/header';
import SectionHeader from '../components/functionals/sectionHeader';
import Slider from '../components/functionals/slider';
import CartReminder from '../components/cartReminder';
import Sidebar from '../components/sidebar';

export default function MainPage() {
  return (
    <>
      <Header />
      <main className="main">
        <Sidebar />
        <Slider />
        <SectionHeader text="Sale Offers" />
        <SaleOffers />
        <About />
        <Footer location="Lorem Ipsum st. 4308" tel="+01488 420 4308" />
        <CartReminder />
      </main>
    </>
  );
}
