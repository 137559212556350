import React from 'react';
import { Link } from 'react-router-dom';

export default function SectionHeader(props) {
  return (
    <div className="sectionHeaderWrapper">
      <div className="container">
        <div className="sectionHeader dfBtw ">
          <span className="text500 textDark textLg">{props.text} </span>
          <Link className="textContr textSm">See more</Link>
        </div>
      </div>
    </div>
  );
}
