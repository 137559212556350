import React from 'react';
import Button from '../components/functionals/button';
import TextReg from '../components/functionals/textReg';
import Header from '../components/header';
import Cookies from 'js-cookie';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import AlertCustom from '../components/alertCustom';
import Sidebar from '../components/sidebar';
import HeartIco from '../img/Heart.svg';
import BalanceIco from '../img/balance.svg';
import LikeIco from '../img/thUp.svg';
import DisLikeIco from '../img/thDn.svg';

export default function ProductPage() {
  const { vendorCode } = useParams();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [comment, setComment] = useState('');
  const [comments, setComments] = useState([]);
  const [error, setError] = useState(null);
  const [rating, setRating] = useState(1); // Начальное значение рейтинга
  const [productRating, setProductRating] = useState([]);
  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const response = await axios.get(
          `https://bydloss.rocks/api-v2/data?search_vendor_code=${vendorCode}`,
        );
        setProduct(response.data.data[0]); // Предполагаем, что API возвращает массив объектов и мы берем первый элемент
        setLoading(false);
      } catch (error) {
        console.error('Error fetching product details:', error);
        setLoading(false);
      }
    };

    // Добавим проверку, чтобы избежать обновления состояния после размонтирования компонента
    let isMounted = true;

    if (isMounted) {
      fetchProductDetails();
    }

    // Очистка isMounted при размонтировании компонента
    return () => {
      isMounted = false;
    };
  }, [vendorCode]);

  // Variables for cart/compare:
  const id = product ? product.id : '';
  const name = product ? product.name : '';
  const price = product ? product.price : '';
  const imageUrl = product ? product.images[0].imageURL : '';
  const vendorC = product ? product.vendorCode : '';
  const prop1 = product ? product.properties.properties1 : '';
  const prop2 = product ? product.properties.properties2 : '';
  const prop3 = product ? product.properties.properties3 : '';
  const prop4 = product ? product.properties.properties4 : '';
  const prop5 = product ? product.properties.properties5 : '';
  const prop6 = product ? product.properties.properties6 : '';

  //Alert code --->
  const [isActive, setActive] = useState(false);

  const callAlert = (e) => {
    setActive(true);
    setTimeout(() => {
      setActive(false);
    }, 3000);
  };

  const activeClass = isActive ? 'activeAlert' : '';
  //Alert code end --->

  // Cart code:
  const [cartItems, setCartItems] = useState([]);

  const handleAddToCart = () => {
    // Получаем текущий массив товаров из кук
    const existingCart = Cookies.get('cart');
    const existingCartArray = existingCart ? JSON.parse(existingCart) : [];

    // Проверяем, есть ли товар с таким id в корзине
    const existingItemIndex = existingCartArray.findIndex((item) => item.id === id);

    if (existingItemIndex !== -1) {
      // Если товар уже в корзине, увеличиваем его количество
      existingCartArray[existingItemIndex].quantity += 1;
    } else {
      // Если товара с таким id нет в корзине, добавляем его
      const newCartItem = { id, name, price, imageUrl, vendorC, quantity: 1 };
      existingCartArray.push(newCartItem);
    }

    // Записываем обновленный массив в куки
    Cookies.set('cart', JSON.stringify(existingCartArray));

    // Обновляем состояние компонента
    setCartItems(existingCartArray);

    callAlert();
  };

  //Compare code start ---->

  const [compareItems, setCompareItems] = useState([]);

  const handleAddToCompare = () => {
    // Получаем текущий массив товаров из кук
    const existingCart = Cookies.get('compare');
    const existingCartArray = existingCart ? JSON.parse(existingCart) : [];

    // Проверяем, есть ли товар с таким id в корзине
    const existingItemIndex = existingCartArray.findIndex((item) => item.id === id);

    if (existingItemIndex !== -1) {
      // Если товар уже в корзине, увеличиваем его количество
      existingCartArray[existingItemIndex].quantity += 1;
    } else {
      // Если товара с таким id нет в корзине, добавляем его
      const newCartItem = { id, name, price, imageUrl, vendorC, prop1, prop2, prop3, prop4, prop5, prop6 ,quantity: 1 };
      existingCartArray.push(newCartItem);
    }

    // Записываем обновленный массив в куки
    Cookies.set('compare', JSON.stringify(existingCartArray));

    // Обновляем состояние компонента
    setCompareItems(existingCartArray);
  };


  //Compare code end ---->


  //Gallery start --->
  const [selectedImage, setSelectedImage] = useState(null);
  const handleThumbnailClick = (image) => {
    setSelectedImage(image.imageURL);
  };
  //Gallery end --->

  const [token, setToken] = useState('');
  const [email, setEmail] = useState('');
  const [tmptoken, setTmptoken] = useState('');

  useEffect(() => {
    const tokenFromCookie = Cookies.get('token');
    console.log('Token from cookie:', tokenFromCookie);

    if (tokenFromCookie) {
      setToken(tokenFromCookie);

      const decodedToken = atob(tokenFromCookie.split('.')[1]);
      console.log('Decoded token:', decodedToken);

      const tokenData = JSON.parse(decodedToken);
      console.log('Token data:', tokenData);

      const userEmail = tokenData.userId;
      console.log('User email:', userEmail);

      const tmpToken = tokenData.tmptoken;
      console.log('User tmpToken:', tmpToken);

      setEmail(userEmail);

      setTmptoken(tmpToken);
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!comment) {
      alert('Please fill in comment field');
      return;
    }

    const requestData = {
      action: 'createComment',
      login: email,
      tmptoken: tmptoken,
      vendorCode: product.vendorCode,
      comment: comment,
      rate: rating.toString(),
    };

    const response = await axios.post(`https://bydloss.rocks/comment/`, requestData);
    // Обработайте ответ от сервера
    if (response.status === 200) {
      console.log('comment sent!')
      setComment('');
    } else {
      alert('Comment sending error');
    }
  };

  // useEffect(() => {
  //   const fetchComments = async () => {
  //     try {
  //       const response = await axios.post(
  //         `https://bydloss.rocks/comment/`, {action: 'createComment', vendorCode: product.vendorCode}
  //       );
  //       setComments(response.data); // Предполагаем, что API возвращает массив объектов и мы берем первый элемент
  //       setLoading(false);
  //     } catch (error) {
  //       console.error('Error fetching comments', error);
  //       setLoading(false);
  //     }
  //   };

  //   // Добавим проверку, чтобы избежать обновления состояния после размонтирования компонента
  //   let isMounted = true;

  //   if (isMounted) {
  //     fetchComments();
  //   }

  //   // Очистка isMounted при размонтировании компонента
  //   return () => {
  //     isMounted = false;
  //   };
  // }, []);
  useEffect(() => {
    if (!product) return;
  
    const fetchComments = async () => {
      try {
        const response = await axios.post(`https://bydloss.rocks/comment/`, {
          action: 'getComment',
          vendorCode: product.vendorCode,
        });
        setComments(response.data.comments); // Извлекаем массив комментариев из ответа
        if (response.data.rates) {
          setProductRating(response.data.rates);
        } else {
          setProductRating([]); // Устанавливаем пустой массив, если rates отсутствуют
        }
      } catch (error) {
        console.error('Error fetching comments', error);
        setError(error);
      }
    };
  
    fetchComments();
  }, [product]);

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', options);
  };
  return (
    <>
      <AlertCustom className={activeClass} text="Product added to cart!" />
      <Header />
      <main className="main">
        <Sidebar />
        <div className="container">
          <div className="productSingleMain df flw w100">
            {loading ? (
              <p>Loading...</p>
            ) : product ? (
              <div className=" w100 ">
                <div className="dfBtw w100 flw">
                  <div className="gallery df aiTop w33">
                    <div className="galleryThumbs">
                      {product.images.map((image, index) => (
                        <div className="galleryThumbsItem">
                          <img
                            key={index}
                            src={image.imageURL}
                            alt={`Thumbnail ${index + 1}`}
                            className="thumbnail"
                            onClick={() => handleThumbnailClick(image)}
                          />
                        </div>
                      ))}
                    </div>
                    <div className="card">
                      <div className="cardImg">
                        <img
                          src={selectedImage || product.images[0].imageURL}
                          alt="Product"
                          className="main-image"
                        />
                        <div className="cardLight topInfo dfCenter">
                          <span className="ttu textXs text700 textContr">Safe pay</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="prodDescr w60">
                    <div className="avliability">
                      <span className="textContr textReg text300">Avaliable</span>
                    </div>
                    <div className="sectionHeader prodName">
                      <h1 className="text700 textDark textLg">{product.name}</h1>
                    </div>
                    <div className="sectionHeader prodName">
                      <span className="text700 textDark textSm">
                        Vendor Code:{product.vendorCode}
                      </span>
                    </div>
                    <div className="sectionHeader prodPrice df">
                      <span className="text700 textDark textXXL">{product.price}</span>
                      <span className="text700 textDark textXXL">UAH</span>
                    </div>
                    {productRating.length > 0 ? (
                      <div className="sectionHeader prodName">
                        <span className="text700 textDark textSm">
                          Rating: {productRating[0].rate.toFixed(2) || 'No rating available yet'}
                        </span>
                      </div>
                    ) : (
                      <div className="lineDivided">
                        <TextReg className="text300 textMuted textXs" text="No rating yet." />
                      </div>
                    )}
                    {/* <div className="sectionHeader prodName">
                      <span className="text700 textDark textMd">Sizes:</span>
                    </div>
                    <div className="df lineDivided">
                      <Button className="btn btnOutline ttu textReg text700" text="34 / xs / 42" />
                      <Button className="btn btnOutline ttu textReg text700" text="36 / s / 44" />
                    </div> */}
                    <div className="dfBtw lineDivided w100 buyHolder">
                      <Button
                        className="btn btnSolid textLight ttu textReg text700 btnBuy"
                        text="Add to cart"
                        onClick={handleAddToCart}
                      />
                    </div>
                    <div className="df bt bb">
                      <button className="btn" onClick={handleAddToCompare}>
                        <img src={BalanceIco} />
                      </button>
                      <button className="btn">
                        <img src={HeartIco} />
                      </button>
                    </div>
                    <div className="df bt bb">
                      <span className="text700 textDark mr10">Seller</span>
                      <span className="text500 textDark">Manager</span>
                    </div>
                    <div className="df mb20 bt bb">
                      <span className="text700 textDark mr10">Payment</span>
                      <span className="text500 textDark">Some payment details here</span>
                    </div>
                    <div className="df bt bb">
                      <span className="text700 textDark mr10">Guarantee</span>
                      <span className="text500 textDark">Some guarantee details here</span>
                    </div>
                  </div>
                </div>
                <div className="df comPropsHolder">
                  <div className="textHolder w50 flw prodPropsHolder">
                    <div className="lineDivided ">
                      <TextReg className="text400 textDark textMd" text="Properties:" />
                    </div>
                    <div className="dfBtw ">
                      <div className="lineDivided mr10 w15">
                        <TextReg className="text300 textMuted textSm" text="Вид:" />
                      </div>
                      <div className="lineDivided mr10">
                        <TextReg
                          className="text400 textDark textMd"
                          text={product.properties.properties1}
                        />
                      </div>
                    </div>
                    <div className="dfBtw ">
                      <div className="lineDivided mr10 w15">
                        <TextReg className="text300 textMuted textSm" text="Країна:" />
                      </div>
                      <div className="lineDivided mr10">
                        <TextReg
                          className="text400 textDark textMd"
                          text={product.properties.properties2}
                        />
                      </div>
                    </div>
                    <div className="dfBtw ">
                      <div className="lineDivided mr10 w15">
                        <TextReg className="text300 textMuted textSm" text="Об'єм:" />
                      </div>
                      <div className="lineDivided mr10">
                        <TextReg
                          className="text400 textDark textMd"
                          text={product.properties.properties3}
                        />
                      </div>
                    </div>
                    <div className="dfBtw ">
                      <div className="lineDivided mr10 w15">
                        <TextReg className="text300 textMuted textSm" text="Міцність:" />
                      </div>
                      <div className="lineDivided mr10">
                        <TextReg
                          className="text400 textDark textMd"
                          text={product.properties.properties4}
                        />
                      </div>
                    </div>
                    <div className="dfBtw ">
                      <div className="lineDivided mr10 w15">
                        <TextReg className="text300 textMuted textSm" text="Розміри:" />
                      </div>
                      <div className="lineDivided mr10">
                        <TextReg
                          className="text400 textDark textMd"
                          text={product.properties.properties5}
                        />
                      </div>
                    </div>
                    <div className="dfBtw ">
                      <div className="lineDivided mr10 w15">
                        <TextReg className="text300 textMuted textSm" text="Тип тари:" />
                      </div>
                      <div className="lineDivided mr10">
                        <TextReg
                          className="text400 textDark textMd"
                          text={product.properties.properties6}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="comments w50">
                    <div className="lineDivided ">
                      <TextReg className="text700 textDark textMd" text="Commentaries" />
                    </div>
                    <form onSubmit={handleSubmit}>
                      <div className="commentsItem">
                        <div className="commentsItemTop dfBtw aiCenter">
                            <div className="lineDivided ">
                                <TextReg className="text700 textDark textMd" text="Leave a comment below" />
                            </div>
                        </div>
                        <div className="commentsItemBody">
                            <div className="lineDivided ">
                              <textarea
                                type="text"
                                rows="10"
                                className="customInput btnOutline w70"
                                placeholder="Your comment here"
                                value={comment}
                                onChange={(e) => setComment(e.target.value)}
                              />
                            </div>
                            <div className="lineDivided ">
                                <TextReg className="text700 textDark textMd" text="Rate the product:" />
                            </div>
                            <div className="lineDivided">
                              <div className="rating">
                                {[1, 2, 3, 4, 5].map((rate) => (
                                  <label key={rate}>
                                    <input
                                      type="radio"
                                      value={rate}
                                      checked={rating === rate}
                                      onChange={() => setRating(rate)}
                                    />
                                    {rate}
                                  </label>
                                ))}
                              </div>
                            </div>
                            <div className="df jcEnd likeHolder">
                                <button 
                                  type="submit"
                                  className="btn btnSolid textLight ttu textReg text700 btnBuy"
                                >
                                Post Comment
                                </button>
                            </div>
                        </div>
                      </div>
                    </form>
                    <div className='commentsList'>
                      {comments.length > 0 ? (
                        comments.map((comment, index) => (
                        <div className="commentsItem" key={index}>
                          <div className="commentsItemTop dfBtw aiCenter">
                            <div className="lineDivided ">
                              <TextReg className="text700 textDark textMd" text={comment.login} />
                            </div>
                            <div className="lineDivided">
                              <TextReg className="text300 textMuted textXs" text={formatDate(comment.date)} />
                            </div>
                          </div>
                          <div className="commentsItemBody">
                            <div className="lineDivided ">
                              <TextReg
                                className="text400 textDark textMd"
                                text={comment.comment}
                              />
                            </div>
                            {/* <div className="df jcEnd likeHolder">
                              <button className="btn">
                                <img src={LikeIco} />
                                (1)
                              </button>
                              <button className="btn">
                                <img src={DisLikeIco} />
                                (0)
                              </button>
                            </div> */}
                          </div>
                        </div>
                        ))
                      ) : (
                        <div className="lineDivided">
                          <TextReg className="text300 textMuted textXs" text='No comments yet.' />
                        </div>
                      )}
                    </div>
                    
                  </div>
                </div>

                <div className="userDescr textHolder">
                  <TextReg
                    className="text400 textDark textMd"
                    text=" User written description here"
                  />
                </div>
                <div className="dfBtw flw">
                  <div className="col">
                    <div className="lineDivided">
                      <TextReg className="text500 textDark textMd" text="Delivery options:" />
                    </div>
                    <div className="lineDivided">
                      <TextReg className="text400 textDark textMd" text="Nova Poshta" />
                    </div>
                  </div>
                  <div className="col">
                    <div className="lineDivided">
                      <TextReg className="text500 textDark textMd" text="Pay options:" />
                    </div>
                    <div className="lineDivided">
                      <TextReg className="text400 textDark textMd" text="Bank Card" />
                    </div>
                    <div className="lineDivided">
                      <TextReg className="text400 textDark textMd" text="Cash" />
                    </div>
                  </div>
                  <div className="col">
                    <div className="lineDivided">
                      <TextReg className="text500 textDark textMd" text="Refund options:" />
                    </div>
                    <div className="lineDivided">
                      <TextReg
                        className="text400 textDark textMd"
                        text="During 14 days after product recieved"
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <p>Product not found</p>
            )}
          </div>
        </div>
      </main>
    </>
  );
}
