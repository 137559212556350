import Shirt from '../img/shirt.jpg';
import React from 'react';
import Button from '../components/functionals/button';
import CustomInput from '../components/functionals/customInput';
import Tabs from '../components/functionals/tabs';
import Header from '../components/header';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import atob from 'atob';

const ManagerInfo = () => {
  return (
    <>
      <div className="tabContentWrapper CRAccWrapper">
        <div className="sectionHeader prodName tac">
          <h1 className="text700 textDark textMd">Private Info</h1>
        </div>
        <div className=" w100 ">
          <div className="userInfoMain ">
            <div className="sectionHeader dfBtw ">
              <span className="text500 textDark textLg">Personal Data </span>
            </div>
            <div className="dfBtw flw">
              <div className="userInfoItem w33 col">
                <span className="lable textReg">Name</span>
                <CustomInput type="text" placeholder="Name" className="customInput" />
              </div>
              <div className="userInfoItem w33 col">
                <span className="lable textReg">Surname</span>
                <CustomInput type="text" placeholder="Surname" className="customInput" />
              </div>
              <div className="userInfoItem w33 col">
                <span className="lable textReg">Family Name</span>
                <CustomInput type="text" placeholder="Family Name" className="customInput" />
              </div>
              <div className="userInfoItem w33 col">
                <span className="lable textReg">Birth Date</span>
                <CustomInput type="date" placeholder="Family Name" className="customInput" />
              </div>
            </div>
          </div>
          <div className="userInfoMain ">
            <div className="sectionHeader dfBtw ">
              <span className="text500 textDark textLg">Contact Data </span>
            </div>
            <div className="dfBtw flw">
              <div className="userInfoItem w33 col">
                <span className="lable textReg">Phone</span>
                <CustomInput type="number" placeholder="Phone" className="customInput" />
              </div>
              <div className="userInfoItem w33 col">
                <span className="lable textReg">Email</span>
                <CustomInput type="text" placeholder="Email" className="customInput" />
              </div>
              <div className="userInfoItem w33 col vh">
                <span className="lable textReg">Email</span>
                <CustomInput type="text" placeholder="Surname" className="customInput" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const ProductsList = () => {
  const [token, setToken] = useState('');
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [userType, setUserType] = useState({});

  useEffect(() => {
    const tokenFromCookie = Cookies.get('token');
    console.log('Token from cookie:', tokenFromCookie);

    if (tokenFromCookie) {
      setToken(tokenFromCookie);

      const decodedToken = atob(tokenFromCookie.split('.')[1]);
      console.log('Decoded token:', decodedToken);

      const tokenData = JSON.parse(decodedToken);
      console.log('Token data:', tokenData);

      const userEmail = tokenData.userId;
      console.log('User email:', userEmail);

      const userTypeInfo = tokenData.userType;
      console.log('User typeInfo:', userTypeInfo);

      setUserType(userTypeInfo.status);
      console.log('User type:', userType);

      setEmail(userEmail);
    }
  }, []);

  const handleGetOrders = async () => {
    setLoading(true);
    try {
      // Отправляем POST-запрос на эндпоинт
      const response = await axios.post(`https://bydloss.rocks/getOrders`, {
        name: 'getOrders',
        login: email,
      });

      // Получаем список товаров из ответа сервера
      const productsData = response.data.my_customer_orders;

      // Устанавливаем товары в состояние
      setOrders(productsData);
    } catch (error) {
      console.error('Ошибка при отправке запроса:', error);
    }
    setLoading(false);
  };

  return (
    <>
      <div className="tabContentWrapper CRAccWrapper">
        {/* <div className="sectionHeader prodName tac">
          <h1 className="text700 textDark textMd">Products List</h1>
        </div> */}
        <div className="w100">
          <div className="sectionHeader prodName tac">
            <h1 className="text700 textDark textMd">Orders list:</h1>
          </div>
          <button
            className="btn btnOutlineDark text700"
            onClick={handleGetOrders}
            disabled={loading}>
            {loading ? 'Отправка...' : 'Получить заказы'}
          </button>
          {loading ? (
            <p>Loading...</p>
          ) : (
            <div>
              <h2>Orders</h2>
              {orders.map((order) => (
                <div key={order.order_id} className="orderMain">
                  <h3 className="text400 textDark textLg">Order #{order.order_id}</h3>
                  <p className="text400 textMuted textMd">
                    Date: {new Date(order.date_created.$date).toLocaleDateString()}
                  </p>
                  <p className="text400 textMuted textMd">Items:</p>
                  <ul>
                    {order.items.map((item) => (
                      <li key={item.product_data._id.$oid}>
                        {item.product_data.images.length > 0 && (
                          <div className="orderItemImg">
                            <img
                              src={item.product_data.images[0].imageURL}
                              alt={item.product_data.name}
                            />
                          </div>
                        )}
                        <div>
                          <p className="text400 textMuted textSm">Name: {item.product_data.name}</p>
                          <p className="text400 textMuted textSm">Quantity: {item.quantity}</p>
                          <p className="text400 textMuted textSm">Price: ${item.price}</p>
                          <p className="text400 textMuted textSm">
                            Date: {new Date(item.product_data.date).toLocaleDateString()}
                          </p>
                        </div>
                      </li>
                    ))}
                  </ul>
                  <p className="text400 textContr textMd">Total Price: ${order.total_price}</p>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default function userPage() {
  const tabs = [
    {
      label: 'User Info',
      content: <ManagerInfo />,
    },
    { label: 'Product List', content: <ProductsList /> },
  ];
  return (
    <>
      <Header />
      <Tabs tabs={tabs} />
    </>
  );
}
