import React from 'react';
import ReactDOM from 'react-dom';
import './css/style.css';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import MainPage from './pages/main';
import CategoryPage from './pages/category';
import ProductPage from './pages/productSingle';
import LogInPage from './pages/logIn';
import SignUpPage from './pages/signUp';
import OrderPage from './pages/order';
import RootAdminPage from './pages/rootAdmin';
import CreateProduct from './pages/createProduct';
import UserCab from './pages/user';
import ManagerCab from './pages/manager';
import ActivationPage from './pages/activation';
import EditProductPage from './pages/editProduct';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" index element={<MainPage />} />
        <Route path="/category/:category" element={<CategoryPage />} />
        <Route path="/products/:vendorCode" element={<ProductPage />} />
        <Route path="/editProduct/:vendorCode" element={<EditProductPage />} />
        {/* <Route path="/search/:query" element={<SearchResult />} /> */}
        <Route path="logIn" element={<LogInPage />} />
        <Route path="signUp" element={<SignUpPage />} />
        <Route path="/order" element={<OrderPage />} />
        <Route path="rootAdmin" element={<RootAdminPage />} />
        <Route path="createProduct" element={<CreateProduct />} />
        <Route path="userCabinet" element={<UserCab />} />
        <Route path="managerCabinet" element={<ManagerCab />} />
        <Route path="/manager/register" element={<ActivationPage />} />
      </Routes>
    </div>
  );
}

export default App;
