import React from 'react';
import Cart from './cart';
import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import CartIcon from '../img/CartIcon.svg';

export default function CartReminder() {
  const [cartAval, setCartAval] = useState([]);
  useEffect(() => {
    // Функция для получения корзины из кук
    const getCart = () => {
      const avalCart = Cookies.get('cart');
      const avalCartArray = avalCart ? JSON.parse(avalCart) : [];
      setCartAval(avalCartArray);
    };

    // Вызываем функцию при загрузке компонента
    getCart();

    // Отслеживаем изменения в куках
    const interval = setInterval(() => {
      getCart();
    }, 1000); // Периодичность проверки, например, каждую секунду

    // Очистка интервала при размонтировании компонента
    return () => clearInterval(interval);
  }, []);

  const [isHidden, setHidden] = useState(false);
  const hiddenClass = isHidden ? 'cartReminderHidden' : '';
  const handleReminder = (e) => {
    setHidden(!isHidden);
  };
  return (
    <div className="cartReminderMain col">
      <div className={`cartReminder ${hiddenClass}`}>
        {cartAval.length > 0 ? (
          <div>
            <div className="cartProductName df tac">
              <span className="text300 textDark textSm">Products are waiting </span>
            </div>
            <Cart />
          </div>
        ) : (
          <span className="text300 textDark textSm">Корзина пуста</span>
        )}
      </div>
      <button
        className="btn btnSolid"
        onClick={() => {
          handleReminder();
        }}>
        <img src={CartIcon} />
      </button>
    </div>
  );
}
