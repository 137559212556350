import React from 'react';
import { Link } from 'react-router-dom';
import { useState } from 'react';

export default function Sidebar() {
  const [isActive, setActive] = useState(false);
  const activeClass = isActive ? 'tabActive' : '';
  const handleClick = () => {
    setActive(true);
  };

  return (
    <>
      <div className="sidebarMain">
        <div className="headerCategories col">
          <Link className={`btn ${activeClass}`} to="/category/Велосипед" onClick={handleClick}>
            Велосипеди
          </Link>
          <Link className={`btn ${activeClass}`} to="/category/Скляна пляшка" onClick={handleClick}>
            Алкоголь
          </Link>
          <Link className={`btn ${activeClass}`} to="/category/lolypop" onClick={handleClick}>
            Lolypop
          </Link>
          <Link
            className={`btn ${activeClass}`}
            to="/category/Pagination test"
            onClick={handleClick}>
            Pagination test
          </Link>
          <Link className={`btn ${activeClass}`} to="/category/Vine" onClick={handleClick}>
            Vine
          </Link>
          <Link className={`btn ${activeClass}`} to="/category/Beer" onClick={handleClick}>
            Beer
          </Link>
          <Link className="btn" to="/category/lolypop" onClick={handleClick}>
            Lolypop
          </Link>
          <Link
            className={`btn ${activeClass}`}
            to="/category/Pagination test"
            onClick={handleClick}>
            Pagination test
          </Link>
          <Link className={`btn ${activeClass}`} to="/category/Vine" onClick={handleClick}>
            Vine
          </Link>
          <Link className={`btn ${activeClass}`} to="/category/Beer" onClick={handleClick}>
            Beer
          </Link>
          <Link className={`btn ${activeClass}`} to="/category/lolypop" onClick={handleClick}>
            Lolypop
          </Link>
          <Link
            className={`btn ${activeClass}`}
            to="/category/Pagination test"
            onClick={handleClick}>
            Pagination test
          </Link>
        </div>
      </div>
    </>
  );
}
