import React, { useState } from 'react';
import Button from '../components/functionals/button';
import CustomInput from '../components/functionals/customInput';
import CheckBox from '../components/functionals/checkBox';
import TextReg from '../components/functionals/textReg';
import axios from 'axios';
import Header from '../components/header';
import { useNavigate } from 'react-router-dom';
import AlertCustom from '../components/alertCustom';

export default function SignUpPage() {
  const [email, setEmail] = useState('');
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Другие действия, если необходимо
  };

  //Alert code --->
  const [isActive, setActive] = useState(false);
  const navigate = useNavigate();

  const callAlert = (e) => {
    setActive(true);
    setTimeout(() => {
      setActive(false);
    }, 1000);
  };

  const activeClass = isActive ? 'activeAlert' : '';
  //Alert code end --->

  const handleSignUp = () => {
    if (!email || !login || !password || !confirmPassword) {
      alert('Please fill in all fields');
      return;
    }

    const requestData = {
      action: 'registration',
      mail: email,
      login: login,
      pass1: password,
      pass2: confirmPassword,
    };

    axios
    .post(`https://bydloss.rocks/auth/registration/`, requestData)
    .then((response) => {
      console.log('Response from server:', response.data);
      if (response.status === 200) {
        callAlert();
        setTimeout(() => {
          navigate('/logIn');
        }, 2000);
      } else {
        console.log('Response from server:', response.data);
      }
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  };

  return (
    <>
      <AlertCustom className={activeClass} text="Your account was successfully registered!" />

      <Header />
      <div className="logInHolder">
        <div className="loginMain col card aiCenter">
          <div className="sectionHeader lineDivided">
            <TextReg className="text700 textDark textXXL" text="Sign Up" />
          </div>
          <form onSubmit={handleFormSubmit}>
            <div className="textHolder df w100">
              <CustomInput
                type="text"
                className="customInput btnOutline w100"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="textHolder df w100">
              <CustomInput
                type="text"
                className="customInput btnOutline w100"
                placeholder="Login"
                value={login}
                onChange={(e) => setLogin(e.target.value)}
              />
            </div>
            <div className="textHolder df w100">
              <CustomInput
                type="password"
                className="customInput btnOutline w100"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="textHolder df w100">
              <CustomInput
                type="password"
                className="customInput btnOutline w100"
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>
            <CheckBox text="I agree with terms of use" />
            <Button className="btn btnSolid text700" text="Sign Up" onClick={handleSignUp} />
          </form>
        </div>
      </div>
    </>
  );
}
