import React from 'react';
import Button from './functionals/button';

export default function Footer(props) {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footerMain df aiTop flw">
          <div className="itemBlank">
            <div className="">
              <span className="textDark text500 textDark"> Contacts: </span>
            </div>
            <div className="lineDivided">
              <span className="textDark text400 textDark">{props.location} </span>
            </div>
            <div className="lineDivided">
              <span className="textDark text400 textDark">{props.tel} </span>
            </div>
          </div>
          <div className="itemBlank mla">
            <div className="df flw">
              <Button text="FAQ" className="btn" />
              <Button text="Privacy policy" className="btn" />
              <Button text="About Company" className="btn" />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
