import React from 'react';
import SectionHeader from './functionals/sectionHeader';
import SectionHeaderSm from './functionals/sectionHeaderSm';
import TextListItem from './functionals/textListItem';
import TextReg from './functionals/textReg';

export default function About() {
  return (
    <section className="section">
      <div className="container">
        <SectionHeader text="About Service" />
        <div className="textHolder">
          <SectionHeaderSm text="About Heading 1" />
          <TextReg
            className="textDark text400  textReg"
            text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam accusamus soluta
            facere officiis, eligendi sequi iste doloremque architecto provident hic ipsum culpa
            dicta fuga commodi ratione? Debitis veniam dolore natus fugit nesciunt excepturi eius
            doloremque, unde qui quisquam sint maiores ab. Cum veniam alias ut molestiae iste
            pariatur minus delectus."
          />
        </div>
        <div className="textHolder">
          <SectionHeaderSm text="About Heading 2" />
          <TextReg
            className="textDark text400  textReg"
            text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam accusamus soluta
            facere officiis, eligendi sequi iste doloremque architecto provident hic ipsum culpa
            dicta fuga commodi ratione? Debitis veniam dolore natus fugit nesciunt excepturi eius
            doloremque, unde qui quisquam sint maiores ab. Cum veniam alias ut molestiae iste
            pariatur minus delectus."
          />
        </div>
        <div className="textHolder">
          <SectionHeaderSm text="About Heading 3" />
          <TextReg
            className="textDark text400  textReg"
            text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam accusamus soluta
            facere officiis, eligendi sequi iste doloremque architecto provident hic ipsum culpa
            dicta fuga commodi ratione? Debitis veniam dolore natus fugit nesciunt excepturi eius
            doloremque, unde qui quisquam sint maiores ab. Cum veniam alias ut molestiae iste
            pariatur minus delectus."
          />
          <ul className="list">
            <TextListItem text="Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ea, omnis.1" />
            <TextListItem text="Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ea, omnis.2" />
            <TextListItem text="Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ea, omnis.3" />
          </ul>
        </div>
      </div>
    </section>
  );
}
