import React from 'react';
import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import AlertCustom from '../components/alertCustom';

export default function Cart({ cartItemsProp }) {
  const [cartItems, setCartItems] = useState([]);

  useEffect(() => {
    // Функция для получения корзины из кук
    const getCartFromCookies = () => {
      const existingCart = Cookies.get('cart');
      const existingCartArray = existingCart ? JSON.parse(existingCart) : [];
      setCartItems(existingCartArray);
    };

    // Вызываем функцию при загрузке компонента
    getCartFromCookies();

    // Отслеживаем изменения в куках
    const intervalId = setInterval(() => {
      getCartFromCookies();
    }, 1000); // Периодичность проверки, например, каждую секунду

    // Очистка интервала при размонтировании компонента
    return () => clearInterval(intervalId);
  }, []);

  // useEffect(() => {
  //   // Получаем текущий массив товаров из кук
  //   const existingCart = Cookies.get('cart');
  //   const existingCartArray = existingCart ? JSON.parse(existingCart) : [];
  //   setCartItems(existingCartArray);
  // }, []); // Вызывается только при монтировании компонента

  //Alert code --->
  const [isActive, setActive] = useState(false);

  const callAlert = (e) => {
    setActive(true);
    setTimeout(() => {
      setActive(false);
    }, 3000);
  };

  const activeClass = isActive ? 'activeAlert' : '';
  //Alert code end --->

  const handleRemoveFromCart = (index) => {
    // Создаем новый массив без выбранного товара
    const updatedCart = cartItems.filter((item, i) => i !== index);

    // Записываем обновленный массив в куки
    Cookies.set('cart', JSON.stringify(updatedCart));

    // Обновляем состояние компонента
    setCartItems(updatedCart);

    callAlert();
  };

  const handleQuantityChange = (index, newQuantity) => {
    // Создаем новый массив с обновленным количеством товара
    const updatedCart = cartItems.map((item, i) => {
      if (i === index) {
        // Проверяем, чтобы количество товара не стало меньше 0
        const updatedQuantity = Math.max(newQuantity, 0);
        return { ...item, quantity: updatedQuantity };
      }
      return item;
    });

    // Записываем обновленный массив в куки
    Cookies.set('cart', JSON.stringify(updatedCart));

    // Обновляем состояние компонента
    setCartItems(updatedCart);
  };

  const calculateTotal = () => {
    // return cartItems.reduce((total, item) => total + item.price * item.quantity, 0);
    const total = cartItems.reduce((total, item) => total + item.price * item.quantity, 0);
    return Math.ceil(total * 100) / 100; // округляем до двух знаков после запятой в большую сторону
  };

  return (
    <>
      <div>
        <AlertCustom className={activeClass} text="Product removed from cart!" />

        {cartItems.length > 0 ? (
          <div>
            {cartItems.map((item, index) => (
              <div className="cartProduct dfBtw aiTop lineDivided flw" key={index}>
                <div className="df aiTop cartInfo">
                  <div className="cartProductImg">
                    <img src={item.imageUrl} alt="product" />
                  </div>
                  <div className="cartProductName">
                    <Link to={`/products/${item.vendorC}`} className="btnUnderline">
                      <span className="text300 textDark textSm">{item.name}</span>
                    </Link>
                  </div>
                  <div className="cartProductName">
                    <span className="text300 textDark textSm">{item.vendorC}</span>
                  </div>
                </div>
                <div className="df aiTop cartCalcM">
                  <div className="cartCalculation col tar">
                    <span className="text300 textMuted textXs">Price</span>
                    <span className="text500 textDark textSm">
                      {item.price} <span className="currency">UAH</span>
                    </span>
                  </div>
                  <div className="cartCalculation col tac clacDemo">
                    <span className="text300 textMuted textXs">Amount</span>
                    <div className="df aiCenter">
                      <button
                        className="btn"
                        onClick={() => handleQuantityChange(index, item.quantity - 1)}>
                        -
                      </button>
                      <span className="text500 textDark textSm">{item.quantity}</span>
                      <button
                        className="btn"
                        onClick={() => handleQuantityChange(index, item.quantity + 1)}>
                        +
                      </button>
                    </div>
                  </div>

                  <div className="col centered itemBlank">
                    <button
                      className="btn textError text900 textMd"
                      onClick={() => handleRemoveFromCart(index)}>
                      &times;
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <span className="text300 textDark textSm">Корзина пуста</span>
        )}
      </div>

      <div className="dividerH">&nbsp;</div>
      <div className="cartNav df jcEnd lineDivided">
        <div className="itemBlank">
          <Link to={{ pathname: '/order', state: { cartItemsProp } }}>
            {' '}
            <button className="btn btnSolid order">
              Submit Order: {calculateTotal()} UAH
            </button>{' '}
          </Link>
        </div>
      </div>
    </>
  );
}
