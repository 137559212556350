import Tabs from '../components/functionals/tabs';
import CustomInput from '../components/functionals/customInput';
import Button from '../components/functionals/button';
import React from 'react';
import Header from '../components/header';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useState, useEffect } from 'react';
import AlertCustom from '../components/alertCustom';
import { useUser } from '../contexts/useUser';
import { Link } from 'react-router-dom';

const CreateAccountContent = () => {
  // JSON format request data
  // const [email, setEmail] = useState('');
  // const [login, setLogin] = useState('');
  //
  // const handleFormSubmit = async (e) => {
  //   e.preventDefault();
  //   // Другие действия, если необходимо
  // };
  // useEffect(() => {
  //   const tokenFromCookie = Cookies.get('token');
  //   console.log('Token from cookie:', tokenFromCookie);

  //   if (tokenFromCookie) {
  //     setToken(tokenFromCookie);

  //     const decodedToken = atob(tokenFromCookie.split('.')[1]);
  //     console.log('Decoded token:', decodedToken);

  //     const tokenData = JSON.parse(decodedToken);
  //     console.log('Token data:', tokenData);

  //     const userEmail = tokenData.userId;
  //     console.log('User email:', userEmail);

  //     setEmail(userEmail);
  //   }
  // }, []);

  //Get All users start --->
  const [users, setUsers] = useState([]);
  const { user } = useUser();
  const showAccs = async () => {
    const requestData = {
      action: 'getall',
      my_login: user.email,
    };
    // Отправьте запрос на сервер для аутентификации пользователя
    try {
      const response = await axios.post(`https://bydloss.rocks/getAllUsers/`, requestData);
      // Обработайте ответ от сервера
      const usersData = response.data;
      setUsers(usersData);
    } catch (error) {
      console.error('Ошибка при отправке запроса:', error);
    }
  };
  // if (users.length === 0) {
  //   return <tr>No users found</tr>;
  // }
  //Get All users end --->

  const [regLink, setRegLink] = useState('');
  const [formData, setFormData] = useState({
    action: 'manager_registration',
    mail: '',
    login: '',
  });

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  //Alert code --->
  const [isActive, setActive] = useState(false);

  const callAlert = (e) => {
    setActive(true);
    setTimeout(() => {
      setActive(false);
    }, 3000);
  };

  const activeClass = isActive ? 'activeAlert' : '';
  //Alert code end --->

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();
    formDataToSend.append('action', formData.action);
    formDataToSend.append('mail', formData.mail);
    formDataToSend.append('login', formData.login);

    try {
      const response = await fetch(`https://bydloss.rocks/superadmin-app/`, {
        method: 'POST',
        body: formDataToSend,
      });

      if (response.status === 200) {
        console.log('Данные успешно отправлены в базу данных');
        callAlert();
        setRegLink(response.data.registration_link);
      } else {
        console.error('Ошибка при отправке данных');
      }
    } catch (error) {
      console.error('Произошла ошибка:', error);
    }
  };

  const handleDelete = (login) => {
    axios
      .post(`https://bydloss.rocks/userDrop`, {
        my_login: user.email,
        login: login,
        action: 'userdrop',
      })
      .then((response) => {
        // Обработка успешного ответа, если необходимо
        console.log('User deleted successfully');
      })
      .catch((error) => {
        // Обработка ошибки, если необходимо
        console.error('Error deleting user:', error);
      });
  };

  return (
    <>
      <AlertCustom className={activeClass} text="Manager successfully created!" />
      <div className="tabContentWrapper CRAccWrapper">
        <div className="sectionHeader prodName tac">
          <h1 className="text700 textDark textMd">Create Account</h1>
        </div>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            className="customInput btnOutline"
            placeholder="Login"
            name="login"
            value={formData.login}
            onChange={handleInputChange}
          />
          <input
            type="text"
            className="customInput btnOutline"
            placeholder="E-mail"
            name="mail"
            value={formData.mail}
            onChange={handleInputChange}
          />
          <button className="btn btnSolid text700" type="submit">
            Add
          </button>
        </form>
        <div className="regLink">
          <h4 className="text700 textDark textMd">{regLink}</h4>
        </div>

        <div className="sectionHeader prodName tac">
          <h1 className="text700 textDark textMd">Existing accounts</h1>
        </div>
        <button className="btn btnSolid text700" onClick={showAccs}>
          Show all
        </button>
        <div className="tableHolder">
          <table className="UsersTable">
            <thead>
              <tr>
                <th>Login</th>
                <th>E-mail</th>
                <th>Card ID</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user, index) => (
                <tr key={index}>
                  <td>{user.login}</td>
                  <td>{user.mail}</td>
                  <td>{user.card_id}</td>
                  <td>{user.manager ? 'Manager' : 'User'}</td>
                  <td className="tac df centered ">
                    {/* <Button className="btn btnSolid text700" text="Save" /> */}
                    <Button
                      className="btn btnSolid btnError text700"
                      text="Delete"
                      onClick={() => handleDelete(user.login)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

const Banner = () => {
  const [slides, setSlides] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const requestData = new FormData();
        requestData.append('action', 'getMyBanners');
        const response = await axios.post(`https://bydloss.rocks/bannerMainPageGet/`, requestData);
        // Обработка успешного ответа
        if ((response.status = 200)) {
          setSlides(response.data);
        } else {
          console.error('Ошибка при отправке данных');
        }
      } catch (error) {
        console.error('Error creating backup:', error);
      }
    };

    fetchData();
  }, []);

  // if (slides.length === 0) {
  //   return <tr>No banners found</tr>;
  // }

  const [file, setFile] = useState(null);
  const [buttonText, setButtonText] = useState('');
  const [forwardUrl, setForwardUrl] = useState('');
  const { user } = useUser();

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData();
      formData.append('bannerfile', file);
      formData.append('buttontext', buttonText);
      formData.append('bannerforwardurl', forwardUrl);
      formData.append('my_login', user.email);

      const response = await axios.post(`https://bydloss.rocks/bannerMainPage/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      console.log('Ответ сервера:', response.data);
      if (response.status === 200) {
        console.log('Данные успешно отправлены в базу данных');
        callAlert();
      } else {
        console.error('Ошибка при отправке данных');
      }
    } catch (error) {
      console.error('Ошибка при отправке данных:', error);
    }
  };

  //Alert code --->
  const [isActive, setActive] = useState(false);

  const callAlert = (e) => {
    setActive(true);
    setTimeout(() => {
      setActive(false);
    }, 1000);
  };

  const activeClass = isActive ? 'activeAlert' : '';
  //Alert code end --->

  const handleDropBanner = async (bannerNumber) => {
    try {
      const formData = new FormData();
      formData.append('action', 'dropMyBanner');
      formData.append('number', bannerNumber);
      formData.append('my_login', user.email);

      const response = await axios.post(`https://bydloss.rocks/bannerMainPageDrop/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      console.log('Ответ сервера:', response.data);
      setSlides(slides.filter((slide) => slide.banner_number !== bannerNumber));
    } catch (error) {
      console.error('Ошибка при отправке данных:', error);
    }
  };

  return (
    <>
      <AlertCustom className={activeClass} text="Banner successfully created!" />
      <form onSubmit={handleSubmit}>
        <div className="tabContentWrapper PCEWrapper">
          <div className="df aiCenter">
            <div className="mr">
              <span className="text400 textDark textMd">Banner Image:</span>
            </div>
            <input
              className="customInput btnOutline w60"
              type="file"
              id="file"
              onChange={handleFileChange}
              accept="image/*"
              required
            />
          </div>
          <div className="df aiCenter">
            <div className="mr">
              <span className="text400 textDark textMd">Button Text:</span>
            </div>
            <input
              type="text"
              className="customInput btnOutline w60"
              id="buttonText"
              value={buttonText}
              onChange={(e) => setButtonText(e.target.value)}
              required
            />
          </div>
          <div className="df aiCenter">
            <div className="mr">
              <span className="text400 textDark textMd">Slide Link:</span>
            </div>
            <input
              type="text"
              className="customInput btnOutline w60"
              id="forwardUrl"
              value={forwardUrl}
              onChange={(e) => setForwardUrl(e.target.value)}
              required
            />
          </div>
          <button className="btn btnSolid text700" type="submit">
            Отправить
          </button>
        </div>
      </form>
      <div className="sectionHeader prodName tac">
        <h1 className="text700 textDark textMd">Existing Banners</h1>
      </div>
      <div className="tableHolder">
        <table className="UsersTable">
          <thead>
            <tr>
              <th>Number</th>
              <th>Date</th>
              <th>Image</th>
              <th>Link</th>
              <th>Button text</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {slides.map((slide, index) => (
              <tr key={index}>
                <td>{slide.banner_number}</td>
                <td>{slide.date}</td>
                <td>
                  <img src={slide.bannerfile} height="40" width="40" />
                </td>
                <td>{slide.bannerforwardurl}</td>
                <td>{slide.buttontext}</td>
                <td>
                  <Button
                    className="btn btnSolid text700 btnError"
                    text="Delete"
                    onClick={() => handleDropBanner(slide.banner_number)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

const BackupRestore = () => {
  const [backupUrl, setBackupUrl] = useState('');
  const [formData, setFormData] = useState({
    action: 'create',
  });

  const handleCreation = async (e) => {
    e.preventDefault();
    try {
      const requestData = new FormData();
      requestData.append('action', formData.action);
      const response = await axios.post(`https://bydloss.rocks/backup`, requestData);
      // Обработка успешного ответа
      if ((response.status = 200)) {
        setBackupUrl(response.data.backup_url);
      } else {
        console.error('Ошибка при отправке данных');
      }
    } catch (error) {
      console.error('Error creating backup:', error);
    }
  };

  const BacupContent = () => {
    return (
      <>
        <form onSubmit={handleCreation}>
          <div className="df aiCenter dfBtw">
            <div className="mr">
              <span className="text400 textDark textMd">Name Type</span>
            </div>
            <CustomInput
              type="text"
              className="customInput btnOutline w60"
              placeholder="Name type"
            />
          </div>
          <div className="df aiCenter dfBtw">
            <div className="mr">
              <span className="text400 textDark textMd">Login</span>
            </div>
            <CustomInput type="text" className="customInput btnOutline w60" placeholder="Login" />
          </div>
          <div className="df aiCenter dfBtw">
            <div className="mr">
              <span className="text400 textDark textMd">Pass</span>
            </div>
            <CustomInput type="number" className="customInput btnOutline w60" placeholder="Pass" />
          </div>
          <div className="df aiCenter dfBtw">
            <div className="mr">
              <span className="text400 textDark textMd">Download URL</span>
            </div>
            <span className="text400 textDark textMd">{backupUrl}</span>
          </div>
          <button className="btn btnSolid text700" type="submit">
            Download
          </button>
        </form>

        <div className="sectionHeader prodName tac">
          <h1 className="text700 textDark textMd">Existing backups</h1>
        </div>
        <div className="tableHolder">
          <table className="UsersTable">
            <thead>
              <tr>
                <th>Name</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>11.02.1998</td>
                <td>
                  <Button className="btn btnSolid text700" text="Unarchive" />
                </td>
              </tr>
              <tr>
                <td>11.02.1998</td>
                <td>
                  <Button className="btn btnSolid text700" text="Unarchive" />
                </td>
              </tr>
              <tr>
                <td>11.02.1998</td>
                <td>
                  <Button className="btn btnSolid text700" text="Unarchive" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </>
    );
  };
  const RestoreContent = () => {
    const [file, setFile] = useState('');
    const [formData, setFormData] = useState({});

    const handleISetFile = (event) => {
      setFile(event.target.files[0]);
    };

    //Alert code --->
    const [isActive, setActive] = useState(false);

    const callAlert = (e) => {
      setActive(true);
      setTimeout(() => {
        setActive(false);
      }, 3000);
    };

    const activeClass = isActive ? 'activeAlert' : '';
    //Alert code end --->

    const handleRestore = async (e) => {
      e.preventDefault();
      try {
        const restoreData = new FormData();
        restoreData.append('file', file);
        restoreData.append('action', 'restore');

        const response = await axios.post(`https://bydloss.rocks/backup`, restoreData);
        // Обработка успешного ответа
        if ((response.status = 200)) {
          callAlert();
        } else {
          console.error('Backup restore failed!');
        }
      } catch (error) {
        console.error('Error restoring Backup:', error);
      }
    };
    return (
      <>
        <AlertCustom className={activeClass} text="Backup successfully restored!" />
        <form onSubmit={handleRestore}>
          <div className="df aiCenter dfBtw">
            <div className="mr">
              <span className="text400 textDark textMd">File Name</span>
            </div>
            <input type="file" className="customInput btnOutline w60" onChange={handleISetFile} />
          </div>
          <div className="df aiCenter dfBtw">
            <div className="mr">
              <span className="text400 textDark textMd">Password</span>
            </div>
            <CustomInput type="number" className="customInput btnOutline w60" placeholder="" />
          </div>
          <button className="btn btnSolid text700" type="submit">
            Restore
          </button>
        </form>
      </>
    );
  };
  const tabs = [
    {
      label: 'Backup',
      content: <BacupContent />,
    },
    { label: 'Restore', content: <RestoreContent /> },
  ];
  return (
    <>
      <div className="tabContentWrapper BRWrapper">
        <Tabs tabs={tabs} />
      </div>
    </>
  );
};

const TemplateEditor = () => {
  const { user } = useUser();
  const [token, setToken] = useState('');
  const [email, setEmail] = useState('');
  const [tmptoken, setTmptoken] = useState('');

  useEffect(() => {
    const tokenFromCookie = Cookies.get('token');
    console.log('Token from cookie:', tokenFromCookie);

    if (tokenFromCookie) {
      setToken(tokenFromCookie);

      const decodedToken = atob(tokenFromCookie.split('.')[1]);
      console.log('Decoded token:', decodedToken);

      const tokenData = JSON.parse(decodedToken);
      console.log('Token data:', tokenData);

      const userEmail = tokenData.userId;
      console.log('User email:', userEmail);

      const tmpToken = tokenData.tmptoken;
      console.log('User tmpToken:', tmpToken);

      setEmail(userEmail);

      setTmptoken(tmpToken);
    }
  }, []);

  // Компонент для одного аккордеона
const Accordion = ({ item, updateItem, deleteItem, addSubItem }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [text, setText] = useState(item.text);
  const [imageUrl, setImageUrl] = useState(item.img || ''); // Используем уже существующее изображение

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = () => {
    setIsEditing(false);
    updateItem(item.translit, text);
  };

  const handleDelete = () => {
    deleteItem(item.translit);
  };

  const handleAddSubItem = () => {
    const newText = prompt('Введите название для нового аккордеона');
    if (newText) {
      addSubItem(item.translit, newText);
    }
  };

  const updateImage = (translit, newImageUrl) => {
    const updateRecursive = (items) => {
      return items.map((item) => {
        if (item.translit === translit) {
          return { ...item, img: newImageUrl }; // Обновляем поле img
        }
        if (item.contain) {
          return { ...item, contain: updateRecursive(item.contain) };
        }
        return item;
      });
    };
  
    setRubricator((prevRubricator) => ({
      ...prevRubricator,
      contain: updateRecursive(prevRubricator.contain),
    }));
  };


  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;
    const formData = new FormData();
    formData.append('file', file);
    formData.append('name', item.translit);

    try {
      const response = await axios.post('https://bydloss.rocks/utils/images/template', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      setImageUrl(response.data.url); // Сохранение URL изображения для предпросмотра
      const newImageUrl = response.data.url;
      updateImage(item.translit, newImageUrl);
      alert('Изображение успешно загружено');
    } catch (error) {

      console.error('Ошибка при загрузке изображения:', error);
      alert('Ошибка при загрузке изображения');

    }

  };

  return (
    <div>
      <div className='accordionEditTmplt' onClick={toggleAccordion} style={{ cursor: 'pointer', fontWeight: 'bold' }}>
        {isEditing ? (
          <input
            value={text}
            onChange={(e) => setText(e.target.value)}
            onBlur={handleSave}
            autoFocus
          />
        ) : (
          <span>{text}</span>
        )}
        <div className='df'>
          <button onClick={handleEdit}>&#9998;</button>
          <button onClick={handleDelete}>&#128465;</button>
          <button onClick={handleAddSubItem}>+</button>
          <input type="file" onChange={handleFileChange} style={{ display: 'none' }} id={`upload-${item.translit}`} />
          <label htmlFor={`upload-${item.translit}`} style={{ cursor: 'pointer' }}>🖼️</label>
        </div>
      </div>

      {/* Предпросмотр загруженного изображения */}
      {imageUrl && (
        <div>
          <img src={imageUrl} alt="Icon preview" style={{ width: '100px', height: '100px', marginTop: '10px' }} />
        </div>
      )}
      
      {isOpen && item.contain && (
        <div style={{ paddingLeft: '20px', marginTop: '10px' }}>
          {item.contain.map((subItem) => (
            <Accordion
              key={subItem.translit}
              item={subItem}
              updateItem={updateItem}
              deleteItem={deleteItem}
              addSubItem={addSubItem}
            />
          ))}
        </div>
      )}
    </div>
  );
};

// Основной компонент

  const [rubricator, setRubricator] = useState(null);

  useEffect(() => {
    const fetchRubricator = async () => {
      try {
        const response = await axios.get('https://bydloss.rocks/python-template/get-rubricator');
        setRubricator(response.data);
      } catch (error) {
        console.error('Ошибка при загрузке рубрикатора:', error);
      }
    };

    fetchRubricator();
  }, []);

  const updateItem = (translit, newText) => {
    const updateRecursive = (items) => {
      return items.map((item) => {
        if (item.translit === translit) {
          return { ...item, text: newText };
        }
        if (item.contain) {
          return { ...item, contain: updateRecursive(item.contain) };
        }
        return item;
      });
    };

    setRubricator((prevRubricator) => ({
      ...prevRubricator,
      contain: updateRecursive(prevRubricator.contain),
    }));
  };

 

  const deleteItem = (translit) => {
    const deleteRecursive = (items) => {
      return items
        .filter((item) => item.translit !== translit)
        .map((item) => ({
          ...item,
          contain: item.contain ? deleteRecursive(item.contain) : [],
        }));
    };

    setRubricator((prevRubricator) => ({
      ...prevRubricator,
      contain: deleteRecursive(prevRubricator.contain),
    }));
  };

  const addSubItem = (parentTranslit, newText) => {
    const addRecursive = (items) => {
      return items.map((item) => {
        if (item.translit === parentTranslit) {
          const newItem = {
            text: newText, // Убираем поле `translit`
            contain: [],
          };
          return {
            ...item,
            contain: item.contain ? [...item.contain, newItem] : [newItem],
          };
        }
        if (item.contain) {
          return { ...item, contain: addRecursive(item.contain) };
        }
        return item;
      });
    };
  
    setRubricator((prevRubricator) => ({
      ...prevRubricator,
      contain: addRecursive(prevRubricator.contain),
    }));
  };
  
  const removeIdField = (data) => {
    if (Array.isArray(data)) {
      return data.map(removeIdField);
    } else if (typeof data === 'object' && data !== null) {
      const sanitized = {};
      for (const key in data) {
        if (key !== '_id') {
          sanitized[key] = removeIdField(data[key]);
        }
      }
      return sanitized;
    }
    return data;
  };
  
  const saveChanges = async () => {
    try {
      const sanitizedRubricator = removeIdField(rubricator);
      const jsonBlob = new Blob([JSON.stringify(sanitizedRubricator, null, 2)], { type: 'application/json' });
      const formData = new FormData();
      formData.append('file', jsonBlob, 'rubricator.json');
      formData.append('login', email);
      formData.append('tmptoken', tmptoken);
  
      await axios.post('https://bydloss.rocks/python-template/edit-rubricator', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      alert('Изменения сохранены успешно');
    } catch (error) {
      console.error('Ошибка при сохранении изменений:', error);
    }
  };

  if (!rubricator) {
    return <div>Загрузка...</div>;
  }

  return (
    <div>
      <h1>{rubricator.text}</h1>
      {rubricator.contain.map((item) => (
        <Accordion
          key={item.translit}
          item={item}
          updateItem={updateItem}
          deleteItem={deleteItem}
          addSubItem={addSubItem}
        />
      ))}
      <button onClick={saveChanges}>Сохранить</button>
    </div>
  );
}
export default function RootAdminPage() {
  const tabs = [
    {
      label: 'Create Account',
      content: <CreateAccountContent />,
    },
    { label: 'Banner', content: <Banner /> },
    { label: 'Backup&Restore', content: <BackupRestore /> },
    { label: 'Template Editor', content: <TemplateEditor /> },

  ];
  return (
    <>
      <Header />
      <Tabs tabs={tabs} />
    </>
  );
}
