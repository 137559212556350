import React from 'react';
import { useState, useEffect } from 'react';
import Shevron from '../../img/shevron.svg';
import axios from 'axios';

export default function Slider() {
  const [slides, setSlides] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const requestData = new FormData();
        requestData.append('action', 'getMyBanners');
        const response = await axios.post(`https://bydloss.rocks/bannerMainPageGet/`, requestData);
        // Обработка успешного ответа
        if ((response.status = 200)) {
          setSlides(response.data);
        } else {
          console.error('Ошибка при отправке данных');
        }
      } catch (error) {
        console.error('Error creating backup:', error);
      }
    };

    fetchData();
  }, []);

  const [currentSlide, setCurrentSlide] = useState(0);

  const goToNextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide === slides.length - 1 ? 0 : prevSlide + 1));
  };

  const goToPrevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide === 0 ? slides.length - 1 : prevSlide - 1));
  };

  const handleSlideClick = (url) => {
    window.location.href = url;
  };

  if (slides.length === 0) {
    return (
      <div className="container">
        <div className="sliderMain df w100">
          <img src="https://via.placeholder.com/400x200" />
        </div>
      </div>
    );
  }

  return (
    <div className="container">
      <div className="sliderMain df w100">
        <button className="sliderPrev" onClick={goToPrevSlide}>
          <img src={Shevron} />
        </button>
        <img src={slides[currentSlide].bannerfile} alt={`Slide ${currentSlide}`} />
        <button
          className="btn btnSolid text700 sliderLink"
          onClick={() => handleSlideClick(slides[currentSlide].bannerforwardurl)}>
          {slides[currentSlide].battontext}
        </button>
        <button className="sliderNext" onClick={goToNextSlide}>
          <img src={Shevron} />
        </button>
      </div>
    </div>
  );
}
