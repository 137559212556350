import Shirt from '../img/shirt.jpg';
import React from 'react';
import Button from '../components/functionals/button';
import CustomInput from '../components/functionals/customInput';
import Tabs from '../components/functionals/tabs';
import Header from '../components/header';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import atob from 'atob';

const ManagerInfo = () => {
  return (
    <>
      <div className="tabContentWrapper CRAccWrapper">
        <div className="sectionHeader prodName tac">
          <h1 className="text700 textDark textMd">Private Info</h1>
        </div>
        <div className=" w100 ">
          <div className="userInfoMain ">
            <div className="sectionHeader dfBtw ">
              <span className="text500 textDark textLg">Personal Data </span>
            </div>
            <div className="dfBtw flw">
              <div className="userInfoItem w33 col">
                <span className="lable textReg">Name</span>
                <CustomInput type="text" placeholder="Name" className="customInput" />
              </div>
              <div className="userInfoItem w33 col">
                <span className="lable textReg">Surname</span>
                <CustomInput type="text" placeholder="Surname" className="customInput" />
              </div>
              <div className="userInfoItem w33 col">
                <span className="lable textReg">Family Name</span>
                <CustomInput type="text" placeholder="Family Name" className="customInput" />
              </div>
              <div className="userInfoItem w33 col">
                <span className="lable textReg">Birth Date</span>
                <CustomInput type="date" placeholder="Family Name" className="customInput" />
              </div>
            </div>
          </div>
          <div className="userInfoMain ">
            <div className="sectionHeader dfBtw ">
              <span className="text500 textDark textLg">Contact Data </span>
            </div>
            <div className="dfBtw flw">
              <div className="userInfoItem w33 col">
                <span className="lable textReg">Phone</span>
                <CustomInput type="number" placeholder="Phone" className="customInput" />
              </div>
              <div className="userInfoItem w33 col">
                <span className="lable textReg">Email</span>
                <CustomInput type="text" placeholder="Email" className="customInput" />
              </div>
              <div className="userInfoItem w33 col vh">
                <span className="lable textReg">Email</span>
                <CustomInput type="text" placeholder="Surname" className="customInput" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const ProductsList = () => {
  const [token, setToken] = useState('');
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [orders, setOrders] = useState([]);
  const [userType, setUserType] = useState({});

  useEffect(() => {
    const tokenFromCookie = Cookies.get('token');
    console.log('Token from cookie:', tokenFromCookie);

    if (tokenFromCookie) {
      setToken(tokenFromCookie);

      const decodedToken = atob(tokenFromCookie.split('.')[1]);
      console.log('Decoded token:', decodedToken);

      const tokenData = JSON.parse(decodedToken);
      console.log('Token data:', tokenData);

      const userEmail = tokenData.userId;
      console.log('User email:', userEmail);

      const userTypeInfo = tokenData.userType;
      console.log('User typeInfo:', userTypeInfo);

      setUserType(userTypeInfo.status);
      console.log('User type:', userType);

      setEmail(userEmail);
    }
  }, []);

  const handleGetOrders = async () => {
    setLoading(true);
    try {
      // Отправляем POST-запрос на эндпоинт
      const response = await axios.post(`https://bydloss.rocks/getOrders/`, {
        name: 'getOrders',
        login: email,
      });

      // Получаем список товаров из ответа сервера
      const productsData = response.data.my_manager_orders;

      // Устанавливаем товары в состояние
      setOrders(productsData);
    } catch (error) {
      console.error('Ошибка при отправке запроса:', error);
    }
    setLoading(false);
  };
  // const handleGetGoods = async () => {
  //   setLoading(true);
  //   try {
  //     // Отправляем POST-запрос на эндпоинт
  //     const response = await axios.post('http://localhost:3007/productGet', {
  //       action: 'getproduct',
  //       login: email,
  //     });

  //     // Получаем список товаров из ответа сервера
  //     const goodsData = response.data.my_manager_products;

  //     // Устанавливаем товары в состояние
  //     setGoods(goodsData);
  //   } catch (error) {
  //     console.error('Ошибка при отправке запроса:', error);
  //   }
  //   setLoading(false);
  // };

  const handleGetGoods = async () => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append('action', 'getproduct');
      formData.append('login', email);

      const response = await axios.post(`https://bydloss.rocks/productget/productGet/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const productsData = response.data.my_manager_products;
      setProducts(productsData);
    } catch (error) {
      console.error('Ошибка при отправке запроса:', error);
    }
    setLoading(false);
  };
  return (
    <>
      <div className="tabContentWrapper CRAccWrapper">
        {/* <div className="sectionHeader prodName tac">
          <h1 className="text700 textDark textMd">Products List</h1>
        </div> */}
        <div className="w100">
          <div className="sectionHeader prodName tac">
            <h1 className="text700 textDark textMd">Orders list:</h1>
          </div>
          <button
            className="btn btnOutlineDark text700"
            onClick={handleGetOrders}
            disabled={loading}>
            {loading ? 'Отправка...' : 'Получить заказы'}
          </button>
          {loading ? (
            <p>Loading...</p>
          ) : (
            <div>
              <h2>Orders</h2>
              {orders.map((order) => (
                <div key={order.order_id} className="orderMain">
                  <h3 className="text400 textDark textLg">Order #{order.order_id}</h3>
                  <p className="text400 textMuted textMd">
                    Date: {new Date(order.date_created.$date).toLocaleDateString()}
                  </p>
                  <p className="text400 textMuted textMd">Items:</p>
                  <ul>
                    {order.items.map((item) => (
                      <li key={item.product_data._id.$oid}>
                        <div>
                          <p className="text400 textMuted textSm">Name: {item.product_data.name}</p>
                          <p className="text400 textMuted textSm">Quantity: {item.quantity}</p>
                          <p className="text400 textMuted textSm">Price: ${item.price}</p>
                          <p className="text400 textMuted textSm">
                            Date: {new Date(item.product_data.date).toLocaleDateString()}
                          </p>
                        </div>
                        {item.product_data.images.length > 0 && (
                          <div className="orderItemImg">
                            <img
                              src={item.product_data.images[0].imageURL}
                              alt={item.product_data.name}
                            />
                          </div>
                        )}
                      </li>
                    ))}
                  </ul>
                  <p className="text400 textContr textMd">Total Price: ${order.total_price}</p>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="w100">
          <div className="sectionHeader prodName tac">
            <h1 className="text700 textDark textMd">Your Goods List:</h1>
          </div>
          <button
            className="btn btnOutlineDark text700"
            onClick={handleGetGoods}
            disabled={loading}>
            {loading ? 'Отправка...' : 'Получить заказы'}
          </button>
          {products.length > 0 ? (
            <div className="tableHolder  ">
              <table className="UsersTable  ">
                <thead>
                  <tr>
                    <th>Product Name</th>
                    <th>Created Date</th>
                    <th>Image</th>
                    <th>Vendor code</th>
                    {/* <th>Purchases</th> */}
                    <th>Price</th>
                    {/* <th>Status</th> */}
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {products.map((product, index) => (
                    <tr key={index}>
                      <td className="tac">{product.name}</td>
                      <td className="tac">{product.date}</td>
                      <td className="tac">
                        <img
                          src={product.images[0].imageURL}
                          alt={product.name}
                          height="40"
                          width="40"
                        />
                      </td>
                      <td className="tac">{product.vendorCode}</td>
                      <td>{product.price}</td>
                      <td className="tac df centered ">
                        <Link
                          to={`/editProduct/${product.vendorCode}`}
                          className="btn btnSolid text700 w50"
                          text="Edit">
                          Edit
                        </Link>
                        <Button className="btn btnOutlineDark text700 w50" text="Delete" />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="btnInfo">Press button above to display Goods</div>
          )}
        </div>
      </div>
    </>
  );
};
export default function managerPage() {
  const tabs = [
    {
      label: 'Manager Info',
      content: <ManagerInfo />,
    },
    { label: 'Product List', content: <ProductsList /> },
  ];
  return (
    <>
      <Header />
      <Tabs tabs={tabs} />
    </>
  );
}
