import React from 'react';
import Button from '../components/functionals/button';
import TextReg from '../components/functionals/textReg';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import atob from 'atob';
import Header from '../components/header';
import AlertCustom from '../components/alertCustom';

export default function CreateProduct() {
  const [token, setToken] = useState('');
  const [email, setEmail] = useState('');
  useEffect(() => {
    const tokenFromCookie = Cookies.get('token');
    console.log('Token from cookie:', tokenFromCookie);

    if (tokenFromCookie) {
      setToken(tokenFromCookie);

      const decodedToken = atob(tokenFromCookie.split('.')[1]);
      console.log('Decoded token:', decodedToken);

      const tokenData = JSON.parse(decodedToken);
      console.log('Token data:', tokenData);

      const userEmail = tokenData.userId;
      console.log('User email:', userEmail);

      setEmail(userEmail);
    }
  }, []);

  const [formData, setFormData] = useState({
    name: '',
    description: '',
    price: '',
    images: [],
    properties: [], // Динамический список дополнительных описаний
    properties1: '',
    properties2: '',
    properties3: '',
    properties4: '',
    properties5: '',
    properties6: '',
    email: '',
  });

  // text inputs processing
  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // image inputs processing
  const handleImageChange = (e) => {
    const files = Array.from(e.target.files); // Преобразовать FileList в массив
    setFormData({
      ...formData,
      images: files,
    });
  };

  const handleAddDescriptionField = () => {
    setFormData({
      ...formData,
      properties: [...formData.properties, ''],
    });
  };

  const handleAdditionalDescriptionChange = (index, value) => {
    const updatedDescriptions = [...formData.properties];
    updatedDescriptions[index] = value;

    setFormData({
      ...formData,
      properties: updatedDescriptions,
    });
  };

  //Alert code --->
  const [isActive, setActive] = useState(false);

  const callAlert = (e) => {
    setActive(true);
    setTimeout(() => {
      setActive(false);
    }, 1000);
  };

  const activeClass = isActive ? 'activeAlert' : '';
  //Alert code end --->

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();
    formDataToSend.append('name', formData.name);
    formDataToSend.append('description', formData.description);
    formDataToSend.append('price', formData.price);
    formDataToSend.append('login', email);
    formDataToSend.append('properties1', formData.properties1);
    formDataToSend.append('properties2', formData.properties2);
    formDataToSend.append('properties3', formData.properties3);
    formDataToSend.append('properties4', formData.properties4);
    formDataToSend.append('properties5', formData.properties5);
    formDataToSend.append('properties6', formData.properties6);

    formData.images.forEach((image, index) => {
      formDataToSend.append(`image${index + 1}`, image);
    });

    formData.properties.forEach((properties, index) => {
      formDataToSend.append(`properties${index + 1}`, properties);
    });

    try {
      const response = await axios.post(`https://bydloss.rocks/product/productCreate`, formDataToSend);

      if ((response.status = 200)) {
        console.log('Данные успешно отправлены в базу данных');
        callAlert();
      } else {
        console.error('Ошибка при отправке данных');
      }
    } catch (error) {
      console.error('Произошла ошибка:', error);
    }
  };

  return (
    <div className="adminPage createProduct">
      <AlertCustom className={activeClass} text="Product successfully created!" />
      <Header />
      <div className="container">
        <div className="adminPageMain">
          <div className="card">
            <div className="sectionHeader lineDivided">
              <TextReg className="text700 textDark textXXL" text="Create new product" />
            </div>
            <form onSubmit={handleSubmit}>
              <div className="textHolder w100 df">
                <input
                  type="text"
                  name="name"
                  className="customInput btnOutline w100"
                  placeholder="Name"
                  value={formData.name}
                  onChange={handleInputChange}
                />
              </div>
              <div className="textHolder w100 df">
                <input
                  type="number"
                  name="price"
                  className="customInput btnOutline w100"
                  placeholder="Price"
                  value={formData.price}
                  onChange={handleInputChange}
                />
              </div>
              <div className="textHolder w100 df">
                <textarea
                  className="customInput btnOutline w100"
                  name="description"
                  placeholder="Description"
                  value={formData.description}
                  onChange={handleInputChange}
                />
              </div>
              <div className="textHolder w100 df">
                <textarea
                  className="customInput btnOutline w100"
                  name="properties1"
                  placeholder="Вид"
                  value={formData.properties1}
                  onChange={handleInputChange}
                />
              </div>
              <div className="textHolder w100 df">
                <textarea
                  className="customInput btnOutline w100"
                  name="properties2"
                  placeholder="Країна"
                  value={formData.properties2}
                  onChange={handleInputChange}
                />
              </div>
              <div className="textHolder w100 df">
                <textarea
                  className="customInput btnOutline w100"
                  name="properties3"
                  placeholder="Об'єм"
                  value={formData.properties3}
                  onChange={handleInputChange}
                />
              </div>
              <div className="textHolder w100 df">
                <textarea
                  className="customInput btnOutline w100"
                  name="properties4"
                  placeholder="Міцність"
                  value={formData.properties4}
                  onChange={handleInputChange}
                />
              </div>
              <div className="textHolder w100 df">
                <textarea
                  className="customInput btnOutline w100"
                  name="properties5"
                  placeholder="Розміри"
                  value={formData.properties5}
                  onChange={handleInputChange}
                />
              </div>
              <div className="textHolder w100 df">
                <textarea
                  className="customInput btnOutline w100"
                  name="properties6"
                  placeholder="Тип тари"
                  value={formData.properties6}
                  onChange={handleInputChange}
                />
              </div>
              <div className="textHolder w100 df">
                <input
                  className="customInput btnOutline"
                  type="file"
                  name="image"
                  onChange={handleImageChange}
                  multiple
                />
              </div>
              {formData.properties.map((properties, index) => (
                <div className="textHolder w100 df">
                  <label key={index}>
                    Additional description {index + 1}:
                    <textarea
                      className="customInput btnOutline w100"
                      value={properties}
                      onChange={(e) => handleAdditionalDescriptionChange(index, e.target.value)}
                    />
                  </label>
                </div>
              ))}
              <button
                className="btn btnSolid text700"
                type="button"
                onClick={handleAddDescriptionField}>
                +
              </button>

              <Button className="btn btnSolid text700" text="Submit Product" type="submit" />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
