import Button from '../components/functionals/button';
import TextReg from '../components/functionals/textReg';
import CustomInput from '../components/functionals/customInput';
import React from 'react';
import { useState } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Header from '../components/header';
import AlertCustom from '../components/alertCustom';

export default function LogInPage() {
  const [email, setEmail] = useState('');
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [token, setToken] = useState('');
  const navigate = useNavigate();

  //Alert code --->
  const [isActive, setActive] = useState(false);

  const callAlert = (e) => {
    setActive(true);
    setTimeout(() => {
      setActive(false);
    }, 1000);
  };

  const activeClass = isActive ? 'activeAlert' : '';
  //Alert code end --->

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    // Другие действия, если необходимо
  };

  const handleLogin = async () => {
    if (!email || !login || !password) {
      alert('Please fill in all fields');
      return;
    }

    const requestData = {
      action: 'login',
      mail: email,
      login: login,
      password: password,
    };
    // Отправьте запрос на сервер для аутентификации пользователя
    const response = await axios.post(`https://bydloss.rocks/auth/login/`, requestData);

    // Обработайте ответ от сервера
    if (response.status === 200) {
      // // Сохраните токен в cookies
      // Cookies.set('token', response.data.token, { sameSite: 'Lax' });

      // // Обновите состояние приложения
      // setToken(response.data.token);
      callAlert();
      setTimeout(() => {
        navigate('/');
      }, 2000);
    } else {
      // Обработайте ошибку аутентификации
      alert('Login failed. Please check your credentials.');
    }
  };

  return (
    <>
      <AlertCustom className={activeClass} text="Login Successfull!" />
      <Header />
      <div className="logInHolder">
        <div className="loginMain col card aiCenter">
          <div className="sectionHeader lineDivided">
            <TextReg className="text700 textDark textXXL" text="Log In" />
          </div>
          <form onSubmit={handleFormSubmit}>
            <div className="textHolder df w100">
              <CustomInput
                type="text"
                className="customInput btnOutline w100"
                placeholder="Login"
                value={login}
                onChange={(e) => setLogin(e.target.value)}
              />
            </div>
            <div className="textHolder df w100">
              <CustomInput
                type="text"
                className="customInput btnOutline w100"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="textHolder df w100">
              <CustomInput
                type="password"
                className="customInput btnOutline w100"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <Button className="btn btnSolid text700" text="Log In" onClick={handleLogin} />
          </form>
        </div>
      </div>
    </>
  );
}
