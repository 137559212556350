import React from 'react';
import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';


export default function Compare() {
  const [cartItems, setCartItems] = useState([]);

  useEffect(() => {
    // Функция для получения корзины из кук
    const getCartFromCookies = () => {
      const existingCart = Cookies.get('compare');
      const existingCartArray = existingCart ? JSON.parse(existingCart) : [];
      setCartItems(existingCartArray);
    };

    // Вызываем функцию при загрузке компонента
    getCartFromCookies();

    // Отслеживаем изменения в куках
    const intervalId = setInterval(() => {
      getCartFromCookies();
    }, 1000); // Периодичность проверки, например, каждую секунду

    // Очистка интервала при размонтировании компонента
    return () => clearInterval(intervalId);
  }, []);

  const handleRemoveFromCart = (index) => {
    // Создаем новый массив без выбранного товара
    const updatedCart = cartItems.filter((item, i) => i !== index);

    // Записываем обновленный массив в куки
    Cookies.set('compare', JSON.stringify(updatedCart));

    // Обновляем состояние компонента
    setCartItems(updatedCart);
  };

  const truncateString = (str, maxLength) => {
    if (str.length > maxLength) {
        return str.slice(0, maxLength) + '...';
    }
    return str;
};

  return (
    <>
      <div >
        {cartItems.length > 0 ? (
          <div className='dfBtw aiTop flw'>
            {cartItems.map((item, index) => (
              <div className="cartProduct dfBtw aiTop lineDivided flw" key={index}>
                <div className="df aiTop cartInfo">
                  <div className="cartProductImg">
                    <img src={item.imageUrl} alt="product" />
                  </div>
                  <div className='col'>
                    <div className="cartProductName">
                        <Link to={`/products/${item.vendorC}`} className="btnUnderline">
                        <span className="text300 textDark textSm">{truncateString(item.name, 20)}</span>
                        </Link>
                    </div>
                    <div className="cartProductName">
                        <span className="text300 textDark textSm">{item.prop1}</span>
                    </div>
                    <div className="cartProductName">
                        <span className="text300 textDark textSm">{item.prop2}</span>
                    </div>
                    <div className="cartProductName">
                        <span className="text300 textDark textSm">{item.prop3}</span>
                    </div>
                    <div className="cartProductName">
                        <span className="text300 textDark textSm">{item.prop4}</span>
                    </div>
                    <div className="cartProductName">
                        <span className="text300 textDark textSm">{item.prop5}</span>
                    </div>
                    <div className="cartProductName">
                        <span className="text300 textDark textSm">{item.prop6}</span>
                    </div>
                  </div>
                  
                </div>
                <div className="df aiTop cartCalcM">
                  <div className="cartCalculation col tar">
                    <span className="text300 textMuted textXs">Price</span>
                    <span className="text500 textDark textSm">
                      {item.price} <span className="currency">UAH</span>
                    </span>
                  </div>
                  <div className="col centered itemBlank">
                    <button
                      className="btn textError text900 textMd"
                      onClick={() => handleRemoveFromCart(index)}>
                      &times;
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <span className="text300 textDark textSm">Comparison list Empty</span>
        )}
      </div>
    </>
  );
}
