import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import atob from 'atob';
import Button from '../components/functionals/button';
import TextReg from '../components/functionals/textReg';
import Header from '../components/header';

const EditProductPage = () => {
  const [product, setProduct] = useState({});
  const [oldImgs, setOldImgs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({});
  const [newImages, setNewImages] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const { vendorCode } = useParams();
  const [token, setToken] = useState('');
  const [email, setEmail] = useState('');

  useEffect(() => {
    const tokenFromCookie = Cookies.get('token');
    console.log('Token from cookie:', tokenFromCookie);

    if (tokenFromCookie) {
      setToken(tokenFromCookie);

      const decodedToken = atob(tokenFromCookie.split('.')[1]);
      console.log('Decoded token:', decodedToken);

      const tokenData = JSON.parse(decodedToken);
      console.log('Token data:', tokenData);

      const userEmail = tokenData.userId;
      console.log('User email:', userEmail);

      setEmail(userEmail);
    }
  }, []);

  //Gallery start --->
  const [selectedImage, setSelectedImage] = useState(null);
  const handleThumbnailClick = (image) => {
    setSelectedImage(image.imageURL);
  };
  //Gallery end --->

  // useEffect(() => {
  //   const fetchProductDetails = async () => {
  //     try {
  //       const response = await axios.get(
  //         `https://bydloss.rocks/data?search_vendor_code=${vendorCode}`,
  //       );
  //       setProduct(response.data.data[0]);
  //       setLoading(false);

  //       // Загрузка изображений с сервера как файлов
  //       // const imageBlobPromises = response.data.data[0].imageURLs.map(async (imageUrl) => {
  //       //   const response = await fetch(imageUrl);
  //       //   const blob = await response.blob();
  //       //   // Получаем тип контента из объекта Blob
  //       //   const contentType = blob.type;
  //       //   // Создаем объект File с указанным типом контента
  //       //   return new File([blob], imageUrl.split('/').pop(), { type: contentType });
  //       // });
  //       const imageBlobPromises = product.images.map(async (image) => {
  //         const imageUrl = image.imageURL;
  //         const response = await fetch(imageUrl);
  //         const blob = await response.blob();
  //         // Получаем тип контента из объекта Blob
  //         const contentType = blob.type;
  //         // Создаем объект File с указанным типом контента
  //         return new File([blob], imageUrl.split('/').pop(), { type: contentType });
  //       });

  //       const images = await Promise.all(imageBlobPromises);
  //       setNewImages(images);
  //       // setImagePreviews(response.data.data[0].imageURLs);
  //       const imageURLs = product.images.map(image => image.imageURL);
  //       setImagePreviews(imageURLs);
  //     } catch (error) {
  //       console.error('Error fetching product details:', error);
  //       setLoading(false);
  //     }
  //   };

  //   fetchProductDetails();
  // }, [vendorCode]);

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        // const response = await axios.get(
        //   `https://bydloss.rocks/data?search_vendor_code=${vendorCode}`
        // );
        const response = await axios.get(
          `https://bydloss.rocks/api-v2/data?search_vendor_code=${vendorCode}`,
          {
            headers: {
              'Access-Control-Allow-Origin': '*'
            }
          }
        );        
        const fetchedProduct = response.data.data[0];
        setProduct(fetchedProduct);
        // const oldImageURLs = fetchedProduct.images.map(image => image.imageURL);
        // setOldImgs(oldImageURLs);

        setLoading(false);
  
        // Убедитесь, что images существует перед их использованием
        if (fetchedProduct && Array.isArray(fetchedProduct.images)) {
          const imageBlobPromises = fetchedProduct.images.map(async (image) => {
            const imageUrl = image.imageURL;
            const response = await fetch(imageUrl, { mode: 'cors' });
            const blob = await response.blob();
            // Получаем тип контента из объекта Blob
            const contentType = blob.type;
            // Создаем объект File с указанным типом контента
            return new File([blob], imageUrl.split('/').pop(), { type: contentType });
          });
  
          const images = await Promise.all(imageBlobPromises);
          setNewImages(images);
          setOldImgs(images);
          // Записываем изображения в переменную oldImgs
  
          const imageURLs = fetchedProduct.images.map(image => image.imageURL);
          setImagePreviews(imageURLs);

        } else {
          console.error('Product or product images are not defined.');
        }
  
        // Вы можете использовать oldImgs здесь или сохранить его в состоянии для дальнейшего использования
        console.log('Old Images:', oldImgs);
  
        // Пример добавления oldImgs в FormData
        // const formData = new FormData();
        // oldImgs.forEach((img, index) => {
        //   formData.append(`oldImage${index}`, img);
        // });
  
        // Используйте formData в дальнейшем по вашему усмотрению
        // console.log('FormData:', formData);
        
      } catch (error) {
        console.error('Error fetching product details:', error);
        setLoading(false);
      }
    };
  
    fetchProductDetails();
  }, [vendorCode]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setNewImages([...newImages, ...files]);
    const previews = files.map((file) => URL.createObjectURL(file));
    setImagePreviews([...imagePreviews, ...previews]);
  };

  const handleImageDelete = (index) => {
    const updatedImagePreviews = [...imagePreviews];
    updatedImagePreviews.splice(index, 1);
    setImagePreviews(updatedImagePreviews);

    // Удаляем изображение из списка newImages, если оно новое (не загружено с сервера)
    const updatedNewImages = [...newImages];
    updatedNewImages.splice(index, 1);
    setNewImages(updatedNewImages);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formDataWithImages = new FormData();

      // Добавляем данные продукта в formDataWithImages

      if (formData.name !== undefined) {
        formDataWithImages.append('name', formData.name); // Если значение было изменено пользователем, добавляем новое значение
      } else {
        formDataWithImages.append('name', product.name); // Если значение не было изменено пользователем, добавляем текущее значение из product
      }

      if (formData.price !== undefined) {
        formDataWithImages.append('price', formData.price);
      } else {
        formDataWithImages.append('price', product.price);
      }

      if (formData.description !== undefined) {
        formDataWithImages.append('description', formData.properties);
      } else {
        formDataWithImages.append('description', product.properties.description);
      }

      // if (formData.properties !== undefined) {
      //   formDataWithImages.append('properties', formData.properties);
      // } else {
      //   formDataWithImages.append('properties', product.properties.properties);
      // }

      if (formData.properties1 !== undefined) {
        formDataWithImages.append('properties1', formData.properties1);
      } else {
        formDataWithImages.append('properties1', product.properties.properties1);
      }

      if (formData.properties2 !== undefined) {
        formDataWithImages.append('properties2', formData.properties2);
      } else {
        formDataWithImages.append('properties2', product.properties.properties2);
      }

      if (formData.properties3 !== undefined) {
        formDataWithImages.append('properties3', formData.properties3);
      } else {
        formDataWithImages.append('properties3', product.properties.properties3);
      }

      if (formData.properties4 !== undefined) {
        formDataWithImages.append('properties4', formData.properties4);
      } else {
        formDataWithImages.append('properties4', product.properties.properties4);
      }

      if (formData.properties5 !== undefined) {
        formDataWithImages.append('properties5', formData.properties5);
      } else {
        formDataWithImages.append('properties5', product.properties.properties5);
      }

      if (formData.properties6 !== undefined) {
        formDataWithImages.append('properties6', formData.properties6);
      } else {
        formDataWithImages.append('properties6', product.properties.properties6);
      }

      // Добавляем новые изображения
      newImages.forEach((image, index) => {
        const imageIndex = index === 0 ? '' : index;
        formDataWithImages.append(`image${imageIndex}`, image, image.name);
        // formDataWithImages.append(`image${index + 1}`, image, image.name); // Если изображение новое, добавляем его содержимое и имя файла
      });
      oldImgs.forEach((image, index) => {
        const imageIndex = index === 0 ? '' : index;
        formDataWithImages.append(`image${imageIndex}`, image, image.name);
      });
      formDataWithImages.append('action', 'modificate');
      formDataWithImages.append('login', email);
      formDataWithImages.append('vendorCode', product.vendorCode);

      await axios.post(`https://bydloss.rocks/productEdit`, formDataWithImages);
      // Обработка успешного ответа
    } catch (error) {
      console.error('Error editing product:', error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Header />
      <main className="main">
        <div className="container">
          <div className="productSingleMain df flw w100 editProduct">
            {loading ? (
              <p>Loading...</p>
            ) : product ? (
              <div className=" w100 ">
                <form onSubmit={handleSubmit}>
                  <div className="dfBtw w100 flw">
                    <div className="gallery df aiTop w50">
                      <div className="galleryThumbs">
                      {product.images.map((image, index) => (
                        <div className="galleryThumbsItem">
                          <img
                            key={index}
                            src={image.imageURL}
                            alt={`Thumbnail ${index + 1}`}
                            className="thumbnail"
                            onClick={() => handleThumbnailClick(image)}
                          />
                        </div>
                      ))}
                      </div>
                      <div className="card">
                        <div className="cardImg">
                          <a href="#">
                            <img
                              src={selectedImage || product.images[0].imageURL}
                              alt="Product"
                              className="main-image"
                            />
                            <div className="cardLight topInfo dfCenter">
                              <span className="ttu textXs text700 textContr">Safe pay</span>
                            </div>
                          </a>
                        </div>
                        <div className="textHolder w100">
                          <input
                            type="file"
                            accept="image/*"
                            onChange={handleImageChange}
                            multiple
                          />
                        </div>
                      </div>
                    </div>
                    <div className="prodDescr w50">
                      <div className="avliability">
                        <span className="textContr textReg text300">Avaliable</span>
                      </div>
                      <div className="sectionHeader prodName">
                        <h1 className="text700 textDark textMd">Name</h1>
                      </div>
                      <div className="textHolder w100">
                        <input
                          type="text"
                          name="name"
                          value={formData.name || product.name}
                          onChange={handleChange}
                          className="customInput"
                        />
                      </div>
                      <div className="sectionHeader prodPrice df">
                        <span className="text700 textDark textXXL">Price</span>
                        <span className="text700 textDark textXXL">UAH</span>
                      </div>
                      <div className="textHolder w100">
                        <input
                          type="text"
                          name="price"
                          value={formData.price || product.price}
                          onChange={handleChange}
                          className="customInput"
                        />
                      </div>
                      <div className="sectionHeader prodName">
                        <span className="text700 textDark textMd">
                          Vendor Code:{product.vendorCode}
                        </span>
                      </div>

                      <div className="textHolder dfBtw flw">
                        <div className="df w50 aiCenter">
                          <div className="lineDivided w40">
                            <TextReg className="text300 textMuted textSm" text="Вид:" />
                          </div>
                          <div className="lineDivided w60 df">
                            <input
                              type="text"
                              name="properties1"
                              value={formData.properties1 || product.properties.properties1}
                              onChange={handleChange}
                              className="customInput df w100"
                            />
                          </div>
                        </div>

                        <div className="df w50 aiCenter">
                          <div className="lineDivided w40 df">
                            <TextReg className="text300 textMuted textSm" text="Країна:" />
                          </div>
                          <div className="lineDivided w60 df">
                            <input
                              type="text"
                              name="properties2"
                              value={formData.properties2 || product.properties.properties2}
                              onChange={handleChange}
                              className="customInput df w100"
                            />
                          </div>
                        </div>
                        <div className="df w50 aiCenter">
                          <div className="lineDivided w40">
                            <TextReg className="text300 textMuted textSm" text="Об'єм:" />
                          </div>
                          <div className="lineDivided w60 df">
                            <input
                              type="text"
                              name="properties3"
                              value={formData.properties3 || product.properties.properties3}
                              onChange={handleChange}
                              className="customInput df w100"
                            />
                          </div>
                        </div>
                        <div className="df w50 aiCenter">
                          <div className="lineDivided w40 ">
                            <TextReg className="text300 textMuted textSm" text="Міцність:" />
                          </div>
                          <div className="lineDivided w60 df">
                            <input
                              type="text"
                              name="properties4"
                              value={formData.properties4 || product.properties.properties4}
                              onChange={handleChange}
                              className="customInput df w100"
                            />
                          </div>
                        </div>
                        <div className="df w50 aiCenter">
                          <div className="lineDivided w40">
                            <TextReg className="text300 textMuted textSm" text="Розміри:" />
                          </div>
                          <div className="lineDivided w60 df">
                            <input
                              type="text"
                              name="properties5"
                              value={formData.properties5 || product.properties.properties5}
                              onChange={handleChange}
                              className="customInput df w100"
                            />
                          </div>
                        </div>
                        <div className="df w50 aiCenter">
                          <div className="lineDivided w40">
                            <TextReg className="text300 textMuted textSm" text="Тип тари:" />
                          </div>
                          <div className="lineDivided w60 df">
                            <input
                              type="text"
                              name="properties6"
                              value={formData.properties6 || product.properties.properties6}
                              onChange={handleChange}
                              className="customInput df w100"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="userDescr textHolder">
                    <hr />
                    <TextReg
                      className="text400 textDark textMd"
                      text=" User written description here"
                    />
                    <div className="df w100">
                      <div className="lineDivided w33">
                        <TextReg className="text300 textMuted textSm" text="Description:" />
                      </div>
                      <div className="lineDivided w70 df">
                        <textarea
                          name="description"
                          value={formData.description || product.properties.description} // Используем значение из formData, если оно было изменено, или текущее значение из properties
                          onChange={handleChange}
                          className="customInput w100"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="dfBtw flw">
                    <div className="col">
                      <div className="lineDivided">
                        <TextReg className="text500 textDark textMd" text="Delivery options:" />
                      </div>
                      <div className="lineDivided">
                        <TextReg className="text400 textDark textMd" text="Nova Poshta" />
                      </div>
                    </div>
                    <div className="col">
                      <div className="lineDivided">
                        <TextReg className="text500 textDark textMd" text="Pay options:" />
                      </div>
                      <div className="lineDivided">
                        <TextReg className="text400 textDark textMd" text="Bank Card" />
                      </div>
                      <div className="lineDivided">
                        <TextReg className="text400 textDark textMd" text="Cash" />
                      </div>
                    </div>
                    <div className="col">
                      <div className="lineDivided">
                        <TextReg className="text500 textDark textMd" text="Refund options:" />
                      </div>
                      <div className="lineDivided">
                        <TextReg
                          className="text400 textDark textMd"
                          text="During 14 days after product recieved"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="dfBtw lineDivided w100 buyHolder">
                    <button
                      type="submit"
                      className="btn btnSolid textLight ttu textReg text700 btnBuy">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            ) : (
              <p>Product not found</p>
            )}
          </div>
        </div>
      </main>
    </>
  );
};

export default EditProductPage;
